<template>
    <v-navigation-drawer
        v-model="drawer"
        :mini-variant.sync="mini"
        fixed
        stateless
        style="height: 100%"
    >
        <v-toolbar flat class="transparent">
        <v-list class="pa-0">
            <v-list-tile avatar @click.stop="mini = !mini">
            <v-list-tile-avatar>
                <img ref="imgRef" id="imgRef" v-bind:src="profilePicture">
            </v-list-tile-avatar>

            <v-list-tile-content>
                <v-list-tile-title>{{ loggedInUser.name }}</v-list-tile-title>
            </v-list-tile-content>

            <v-list-tile-action>
                <v-btn
                icon
                @click.stop="mini = !mini"
                >
                <v-icon>chevron_left</v-icon>
                </v-btn>
            </v-list-tile-action>
            </v-list-tile>
        </v-list>
        </v-toolbar>

        <v-list class="pt-0"  >
            <v-divider></v-divider>

            <v-list-tile   v-if="showHomeScreen">
                <v-list-tile-action>
                    <v-icon>home</v-icon>
                </v-list-tile-action>

                <v-list-tile-content @click="goToHome()">
                <v-list-tile-title>Home Screen</v-list-tile-title>
                </v-list-tile-content>
            </v-list-tile>

            <v-list-tile  >
                <v-list-tile-action>
                    <v-icon>account_box</v-icon>
                </v-list-tile-action>

                <v-list-tile-content @click="goToMyAccount()">
                <v-list-tile-title>My Account</v-list-tile-title>
                </v-list-tile-content>
            </v-list-tile>

            <v-list-tile >
                <v-list-tile-action >
                    <v-icon>fa-barcode</v-icon>
                </v-list-tile-action>

                <v-list-tile-content @click="showPairingQR()">
                <v-list-tile-title>Pairing QR Code</v-list-tile-title>
                </v-list-tile-content>
            </v-list-tile>            

            <v-list-tile  style="position: fixed; bottom: 0;">
                <v-list-tile-action>
                    <v-icon>power_settings_new</v-icon>
                </v-list-tile-action>

                <v-list-tile-content @click="doLogout()">
                <v-list-tile-title>Logout</v-list-tile-title>
                </v-list-tile-content>
            </v-list-tile>





        </v-list>
    </v-navigation-drawer>    
</template>

<script>

import { mapGetters } from 'vuex';

export default {
    props: ["showHomeScreen"],
    mounted() {

    },
    data () {
        return {

            drawer: true,
            mini: true,
            right: null,
        }
    },
    computed: {
        ...mapGetters({
            currentDomain: "getCurrentDomain",
            isLoggedIn: "isLoggedIn",
            loggedInUser: "getLoggedInUser",
        }),
        profilePicture()
        {
            return `${this.currentDomain}/user-picture?id=${this.loggedInUser.id}`;
        }
    },
    methods: {
        goToHome() {
            this.$router.push("mainmenu");
        },
        goToMyAccount() {
            this.$router.push("useraccount");
        },
        doLogout()
        {
            this.$store.dispatch("doLogOut");
        },
        showPairingQR()
        {
            this.$store.commit("setShowPairingQR", true);
        }
    },
}
</script>
