import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import { i18n } from './i18n'
import { Trans } from './plugins/Translation'

import VueCroppie from 'vue-croppie';
import VueResource from 'vue-resource';
import VueCookies from 'vue-cookies'
import VueNativeSock from 'vue-native-websocket'
import VueQrcodeReader from 'vue-qrcode-reader'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import ToggleButton from 'vue-js-toggle-button'
import VueClipboard from 'vue-clipboard2'

import Recaptcha from './components/Recaptcha'
import HistoryComponent from './components/History'
import ScoreViewerPlayer from './components/ScoreViewerPlayer'
import GeneralHeader from './components/GeneralHeader'
import NavBar from './components/NavBar'
import ImageUploader from "vue-image-upload-resize";
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import VueYoutube from 'vue-youtube'
import VueApexCharts from 'vue-apexcharts'

import  { store } from './store/index'

export const eventBus = new Vue();

Vue.use(require('vue-moment'));
Vue.use(ImageUploader);
Vue.use(VueResource);
Vue.use(VueCroppie);
Vue.use(VueCookies);
Vue.use(VueQrcodeReader);
Vue.use(ToggleButton);
Vue.use(VueApexCharts)

Vue.use(VueTelInputVuetify, {
    vuetify,
});
Vue.use(VueYoutube);


Vue.component(VueQrcode.name, VueQrcode);
Vue.component('general-header', GeneralHeader);
Vue.component('nav-bar', NavBar);
Vue.component('score-viewer-player', ScoreViewerPlayer);
Vue.component('history-component', HistoryComponent);
Vue.component('recaptcha-component', Recaptcha);
Vue.component('apexchart', VueApexCharts)


import { VueReCaptcha } from "vue-recaptcha-v3";
Vue.use(VueReCaptcha, { siteKey: "6LdzIP4ZAAAAAFZIuCeEfolgHZNUnDlKTD2BNMvK" });

var domain = "snoocare.com";
if (process.env.NODE_ENV == "development") {
    if (process.env.VUE_APP_SNOOCARE_DOMAIN != undefined && process.env.VUE_APP_SNOOCARE_DOMAIN != "")
        domain = process.env.VUE_APP_SNOOCARE_DOMAIN;
}
else
    domain = window.location.hostname;



Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)


console.log(`Selected domain: ${domain}`);
Vue.use(VueNativeSock, `wss://${domain}/datastream`, {
    connectManually: true,
    reconnection: true,
    reconnectionDelay: 3000,
    store: store
});

import './assets/css/mainstyle.css' // Ensure you are using css-loader
import 'vuetify/dist/vuetify.min.css' // Ensure you are using css-loader


VueCookies.config('7d')
Vue.use(VueClipboard)

Vue.config.productionTip = false

new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
