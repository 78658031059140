<template>
    <v-container
        style="padding-left: 10px; padding-right: 10px; padding-top: 0"
    >
        <general-header height="100" style></general-header>

        <v-dialog v-model="showPolicy">
            <v-card>
                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                    style="color: black"
                >
                    תנאי פרטיות
                </v-card-title>

                <v-card-text>
                    <div style="height: 400px; overflow-y: scroll">
                        <privacy-policy></privacy-policy>
                    </div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" flat @click="showPolicy = false">
                        אני מסכים
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showTerms">
            <v-card>
                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                    style="color: black"
                >
                    תנאי שימוש
                </v-card-title>

                <v-card-text>
                    <div style="height: 400px; overflow-y: scroll">
                        <terms-of-use></terms-of-use>
                    </div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" flat @click="showTerms = false">
                        אני מסכים
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="pleaseWait" persistent width="400">
            <v-container grid-list-md text-center>
                <v-layout row wrap>
                    <v-flex xs12>
                        <v-card>
                            <v-card-text class="text-lg-center headline">
                                אנא המתן...<br /><br />
                                <v-progress-circular
                                    :size="90"
                                    :width="7"
                                    color="#079038"
                                    indeterminate
                                ></v-progress-circular>
                            </v-card-text>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-dialog>

        <v-form id="nativeForm" v-model="valid">
            <v-layout text-center wrap>
                <v-flex xs12 v-if="!registrationCompleted">
                    <v-text-field
                        dense solo rounded
                        v-model="fullname"
                        :rules="[rules.required]"
                        :label="$t('customerRegistration.fullname')"
                        name="txtFullName"
                    ></v-text-field>

                    <vue-tel-input-vuetify 
                        dir="ltr" 
                        v-model="phoneTest" 
                        @input="onPhoneInput" 
                        :placeholder="$t('customerRegistration.phone')" 
                        :rules="[rules.required]"  solo dense rounded label="" 
                        :preferredCountries="['il', 'gb']" 
                        :valid-characters-only="true" >
                    </vue-tel-input-vuetify>
                    
                    <v-text-field v-model="email" dense solo rounded  :rules="[rules.email, rules.required]" :label="$t('customerRegistration.email')" name="txtEmail" ref="txtEmail"></v-text-field>
                    <!-- <div style="font-size: 10pt" align="left">You can add phone number to receive additional game notification by SMS. We will not pass your number to 3rd party or call you.</div>          
            <v-text-field
              v-model="password"
              :append-icon="showPassword ? 'visibility' : 'visibility_off'"
              :rules="[rules.required, rules.min]"
              :type="showPassword ? 'text' : 'password'"
              name="txtPassword"
              label="Password:"
              @click:append="showPassword = !showPassword"
            ></v-text-field>
             -->
                    <!-- <vue-recaptcha sitekey="6Le6SasUAAAAAADr2EbCkIloscf0OFxhMCuHuCP1" theme="dark" @verify="verifiedCaptch">
            </vue-recaptcha> -->
                    <!-- <div id="registration-captcha"></div>
            <div align="left" v-if="refreshCaptcha">
                <v-btn >Refresh CAPTCHA</v-btn>
            </div> -->
                    <!-- <div class="g-recaptcha" data-sitekey="6Le6SasUAAAAAADr2EbCkIloscf0OFxhMCuHuCP1" :data-callback="this.lala"></div> -->
                    <!-- <recaptcha-component ref="recaptcha" @verify="lala"></recaptcha-component> -->

                    <!-- <v-checkbox  v-model="agreeToTerms">
              <template v-slot:label>
                <div>
                  אני מסכים <a @click.stop="showPolicy = true">לתנאי הפרטיות</a>&nbsp;<a @click.stop="showTerms = true">ותנאי השימוש</a>
                </div>
              </template>
            </v-checkbox>                  -->
                    <!-- <v-img :src="userPicture" class="my-3" contain height="180"></v-img> -->
                    <!-- <img ref="imgRef" id="imgRef" v-bind:src="userPictureCropped" v-show="userPictureCropped != null" height="180" width="180">
            <div>
              <image-uploader
                :preview="false"
                :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                capture="user"
                :debug="0"
                doNotResize="gif"
                :autoRotate="true"
                outputFormat="verbose"
                @input="setImage"
              >

                <label for="fileInput" slot="upload-label">
                  <figure>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                    >
                      <path fill="white"
                        class="path1"
                        d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"
                      ></path>
                    </svg>
                  </figure>
                  <span class="upload-caption">{{ hasImage ? "Replace" : "Take a picture"}}</span>
                </label>              
              </image-uploader> -->
                    <!-- <div class="upload-btn-wrapper">
                    <v-btn color="primary">Take a picture</v-btn>
                    <input type="file" id="player-picture"  accept="image/*" capture="camera" @change="loadImage($event.target.files)"></input>
                </div>                            -->
                    <!-- </div> -->
                    <v-btn
                        color="success"
                        @click="submit()"
                        :disabled="
                            !valid || !phone.isValid || captchaToken == ''
                        "
                        >הירשם</v-btn
                    >
                </v-flex>
                <v-flex xs12 v-if="registrationCompleted">
                    <p>בשניות הקרובות יישלח לך SMS להפעלת החשבון</p>
                    <p>
                        <a @click="backToLogin()">חזור למסך כניסה</a>
                    </p>
                </v-flex>
            </v-layout>
        </v-form>
        <v-layout
            id="got-to-home"
            row
            style="margin-top: 20pt; width: 100%; align-items: center"
            @click="backToLogin()"
        >
            <v-flex xs2 text-center>
                <img :src="require('../assets/logo.png')" height="50" />
            </v-flex>
            <v-flex xs10> חזור למסך כניסה </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import VueTelInput from "vue-tel-input";
import VueRecaptcha from "vue-recaptcha";

import PrivacyPolicy from "@/components/PrivacyPolicy";
import TermsOfUse from "@/components/TermsOfUse";

export default {
    components: {
        VueTelInput: async () => {
            const { VueTelInput } = await import("vue-tel-input");
            return VueTelInput;
        },
        VueRecaptcha,
        "privacy-policy": PrivacyPolicy,
        "terms-of-use": TermsOfUse,
    },
    mounted() {
        var self = this;

        this.$vuetify.rtl = this.$t('rtl') == 'true';
        //this.$store.dispatch("requestGeoLocation");
        this.$nextTick(() => {
            self.recaptcha();
        });

        // this.$refs.recaptcha.
        // var self = this;
        // setTimeout(function() {
        //     grecaptcha.render('registration-captcha', {
        //         'class': "g-recaptcha",
        //         'sitekey' : '6Le6SasUAAAAAADr2EbCkIloscf0OFxhMCuHuCP1',
        //         'callback' : self.verifiedCaptch,
        //         'expired-callback': self.showRefreshCAPTCHA,
        //         'theme' : 'dark'
        //     });
        // }, 600);
    },
    data() {
        return {
            refreshCaptcha: false,
            captchaToken: "",
            valid: false,
            showPassword: false,
            password: "",
            userPictureDialog: false,
            userPictureFile: "",
            userPicture: "",
            userPictureCropped: null,
            userPictureCroppedURL: "",
            email: "",
            phoneTest: null,
            phone: {
                number: "",
                isValid: false,
                country: undefined,
            },
            fullname: "",
            registrationCompleted: false,
            pleaseWait: false,
            hasImage: false,
            agreeToTerms: false,
            showPolicy: false,
            showTerms: false,
        };
    },
    computed: {
        ...mapGetters({
            currentDomain: "getCurrentDomain",
            geoLocation: "getGeolocation",
            rules: "getInputRules",
        }),
    },
    methods: {
        async recaptcha() {
            await this.$recaptchaLoaded()
            this.captchaToken = await this.$recaptcha('login')
        },
        onPhoneInput(formattedNumber, { number, isValid, country }) {
            this.phone.number = number;
            this.phone.isValid = isValid;
            this.phone.country = country && country.name;
        },
        backToLogin() {
            this.$router.push("login");
        },
        // loadImage(files) {
        //     if (files.length > 0)
        //     {
        //         var reader = new FileReader();
        //         var self = this;
        //         reader.onload = function (e) {
        //             self.userPicture = e.target.result;
        //             self.$refs.croppieRef.bind({ url: e.target.result });
        //             self.userPictureDialog = true;
        //         };
        //         reader.readAsDataURL(files[0]);
        //     }
        // },
        setImage(output) {
            this.hasImage = true;
            this.userPicture = output;
            this.$refs.croppieRef.bind({ url: output.dataUrl });
            this.userPictureDialog = true;
        },

        acceptPicture() {
            let options = {
                format: "png",
                circle: true,
            };
            var self = this;
            this.$refs.croppieRef.result(options, (output) => {
                self.userPictureDialog = false;
                //self.userPictureCropped = output;
                self.$refs.croppieRef.result(options);
            });
        },
        submit() {
            if (!this.phone.isValid) {
                this.$store.dispatch("showMessageDialog", {
                    Title: "רישום",
                    Body: "מספר טלפון אינו תקין",
                    Type: "error",
                });
                return;
            }

            if (this.captchaToken == "") {
                this.$store.dispatch("showMessageDialog", {
                    Title: "רישום",
                    Body: 'יש לסמן "אני לא רובט"',
                    Type: "error",
                });
                return;
            }

            this.pleaseWait = true;
            this.$store.dispatch("requestGeoLocation");
            var self = this;
            setTimeout(function () {
                self.$http
                    .post(`${self.currentDomain}/register-by-phone`, {
                        fullname: self.fullname,
                        email: self.email,
                        geo: JSON.stringify(self.geoLocation),
                        phone: self.phone.number.e164,
                        captcha: self.captchaToken,
                    })
                    .then(
                        (response) => {
                            if (response.status == 200) {
                                self.pleaseWait = false;
                                self.registrationCompleted = true;
                            }
                        },
                        (response) => {
                            self.pleaseWait = false;
                            console.log("Error:");
                            self.$store.dispatch("showMessageDialog", {
                                Title: "רישום",
                                Body: response.body,
                                Type: "error",
                            });
                            console.log(response);
                        }
                    );
            }, 1000);
        },
        result(output) {
            this.userPictureCropped = this.userPictureCroppedURL = output;
        },
        update(val) {
            console.log(val);
        },

        facebookLogin() {
            // FB.logout(function (response) {
            //     //Do what ever you want here when logged out like reloading the page
            //     window.location.reload();
            // });
            var self = this;
            FB.login(function (response) {
                if (response.authResponse) {
                    console.log("Welcome!  Fetching your information.... ");
                    FB.api("/me", function (response) {
                        console.log(response);
                        console.log("Good to see you, " + response.name + ".");
                        var pictureRequest = "/" + response.id + "/picture";
                        console.log("pictureRequest: " + pictureRequest);
                        FB.api(
                            pictureRequest,
                            "GET",
                            { redirect: "false", type: "large" },
                            function (response) {
                                console.log("Picture response:");
                                if (typeof response.data != undefined) {
                                    self.userPicture = response.data.url;
                                    self.$refs.croppieRef.bind({
                                        url: response.data.url,
                                    });
                                    console.log(response);
                                }
                            }
                        );
                    });
                } else {
                    console.log(
                        "User cancelled login or did not fully authorize."
                    );
                }
            });
        },
    },
};
</script>

<style>
.vue-tel-input {
    color: black !important;
    font-weight: normal !important;
}

input[type="tel"] {
    color: white !important;
}
</style>
