<template>
  <v-app style="">
    <v-dialog
      v-model="messageDialogShow"
      width="500"
    >
      <v-card >
        <v-card-title class="headline grey lighten-2" :class="messageDialogType">
          {{ messageDialogTitle }}
        </v-card-title>

        <v-card-text><pre style="white-space: pre-wrap; font-family: Roboto,sans-serif!important">{{ messageDialogBody }}</pre></v-card-text>
        <v-divider></v-divider>
        <v-card-actions v-if="messageDialogConfirmCallback == null">
          <v-spacer></v-spacer>
          <v-btn color="primary"  @click="messageDialogShow = false">{{ $t("general.ok") }}</v-btn>
        </v-card-actions>
        <v-card-actions v-if="messageDialogConfirmCallback != null">
          <v-spacer></v-spacer>
          <v-btn color="error"  @click="confirmCallback">{{ $t("general.yes") }}</v-btn>
          <span style="width: 10px"></span>
          <v-btn color="primary"  @click="messageDialogShow = false">{{ $t("general.no") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>    
    <v-main>
        <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import { eventBus } from './main'
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  created() {
    eventBus.$on('onShowMessageDialog', (payload) => {
      this.messageDialogShow = true;
      this.messageDialogTitle = payload.Title;
      this.messageDialogBody = payload.Body;
      this.messageDialogType = payload.Type;
      this.messageDialogConfirmCallback = null;
      this.messageDialogConfirmCallbackParams = null;
      
      if (typeof payload.Callback != 'undefined' && payload.Callback != null)
        this.messageDialogConfirmCallback = payload.Callback;

      if (typeof payload.CallbackParams != 'undefined' && payload.CallbackParams != null)
        this.messageDialogConfirmCallbackParams = payload.CallbackParams;

    });
  },  
  data () {
    return {
      activeScreen: this.$router.currentRoute.name,
      messageDialogShow: false,
      messageDialogTitle: "Error",
      messageDialogBody:  "",
      messageDialogType: "error",
      messageDialogConfirmCallback: null,
    }
  },
  computed: {
      ...mapGetters({
      }),
  },  
  methods: {
    goTo(path) {
      this.$router.push(path)
    },
    confirmCallback()
    {
      this.messageDialogShow = false;
      this.messageDialogConfirmCallback(this.messageDialogConfirmCallbackParams);
    }


  },
  watch: {
    $route (to, from){
        
        this.activeScreen = to.name;
    }
  }
}
</script>
