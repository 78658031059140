//Your simple template
<template>
  <div 
  id="g-recaptcha"
  class="g-recaptcha"
  :data-sitekey="sitekey">
  </div>
</template>

<script>
export default {
  data () {
    return {
      sitekey: '6Le6SasUAAAAAADr2EbCkIloscf0OFxhMCuHuCP1',
      vueWidgtId: 0
    }
  },
  methods: {
  //some vuejs methods
    execute () {
        console.log("AAA");
      window.grecaptcha.execute(this.vueWidgtId)
    },
    reset () {
    //reset using vuejs
      window.grecaptcha.reset(this.vueWidgtId)
    },
    render () {
    //render Functions using vuejs
      var self = this;
      if (window.grecaptcha) {
        this.vueWidgtId = window.grecaptcha.render('g-recaptcha', {
          sitekey: self.sitekey, //get your sitekey
          size: 'invisible',
          callback: (results) => {
              console.log("AAAAAAAAAAAAAAAAAAAAAAAA");
              console.log(results);
            //this.$emit('verify', results)
            //this.reset() 
          }
        })
      }
    }
  },
  mounted () {
    //this.render();
    // console.log("AA")
    // var self = this;
    // setTimeout(function() {
    //     self.render();
    // }, 1000);
    
  }
}
</script>