<template>
    <div class="beta-page">
        <div class="demo-bg"></div>
        <div style="align-self: center; z-index: 99">
            <div id="logo-container">
                <img src="../assets/beta.png" alt="" />
            </div>
        </div>
        <div style="height: 20px"></div>
        <div style="width: 95%; text-align: center; z-index: 99">
            <div style="max-width: 640px;margin: 0 auto;font-family: Helvetica, sans-serif;">
                <template v-if="loading">
                    <v-layout text-center wrap>
                        <v-flex xs12>
                        <v-card>        
                            <v-card-text class="text-lg-center headline">
                                <v-progress-circular
                                :width="5"
                                :size="150"
                                color="green"
                                indeterminate
                                style="font-size: 20px"
                                >
                                Loading...
                                </v-progress-circular>
                            </v-card-text>
                        </v-card>
                        </v-flex>  
                    
                    </v-layout>
                </template>
                <template v-if="!loading">
                    <div style="text-align: left; font-weight: bold; font-size: 16pt" v-show="!successSubmit && formData.Active">
                        {{ formData.Header }}
                    </div>
                    <div style="text-align: left" v-show="!successSubmit && formData.Active" v-html="formData.Description">
                        
                    </div>
                    <div style="background-color: rgba(0,0,0,0.5); padding: 10px; border-radius: 20px">

                        <div v-show="successSubmit">
                            <img src="../assets/images/success.svg" alt="" width="100" /><br>
                            <span style="font-weight: bold; font-size: 30px">Thank you!</span><br>
                            {{ formData.SuccessMessage }}
                        </div>
                        
                        <div v-show="formData.Active == false">
                            <img src="../assets/images/fail.png" alt="" width="100" /><br>
                            <span style="font-weight: bold; font-size: 30px">Sorry, this form in no longer active</span>
                        </div>

                        <v-form id="nativeForm" v-model="valid" v-show="!successSubmit && formData.Active">
                            <v-layout text-center wrap>
                                <v-flex xs4>
                                    <v-text-field
                                        v-model="fullname"
                                        :rules="[rules.required]"
                                        label="Full name*:"
                                        name="txtFullName"
                                    ></v-text-field>
                                </v-flex>
                                <v-flex xs1></v-flex>
                                <v-flex xs7>
                                    <v-text-field
                                        v-model="clubName"
                                        :rules="[rules.required]"
                                        label="Club name*:"
                                        name="txtClubname"
                                    ></v-text-field>
                                </v-flex>

                                <v-flex xs4>
                                    <v-text-field
                                        v-model="country"
                                        :rules="[rules.required]"
                                        label="Country*:"
                                        name="txtCountry"
                                    ></v-text-field>
                                </v-flex>
                                <v-flex xs1></v-flex>
                                <v-flex xs7>
                                    <v-text-field
                                        v-model="email"
                                        :rules="[rules.email, rules.required]"
                                        label="E-mail address*:"
                                        name="txtEmail"
                                        ref="txtEmail"
                                    ></v-text-field>
                                </v-flex>

                                <v-flex xs3>
                                    <v-text-field
                                        v-model="numberOfTables"
                                        :rules="[rules.required]"
                                        label="Number of tables*:"
                                        name="txtNumOfTables"
                                    ></v-text-field>
                                </v-flex>
                                <v-flex xs1></v-flex>
                                <v-flex xs3>
                                    <v-select
                                        :items="['Yes', 'No']"
                                        v-model="hasInternet"
                                        :rules="[rules.required]"
                                        label="Internet*:"
                                        name="txtInternetConnection"
                                    ></v-select>
                                </v-flex>
                                <v-flex xs1></v-flex>
                                <v-flex xs4>
                                    <v-text-field
                                        v-model="bandwidth"
                                        label="bandwidth (down/up):"
                                        name="txtBandwidth"
                                    ></v-text-field>
                                </v-flex>

                                <v-flex xs12>
                                    <v-text-field
                                        v-model="clubLink"
                                        label="Link to the club's site / Facebook page / pictures:"
                                        name="txtLink"
                                    ></v-text-field>
                                </v-flex>

                                <v-flex xs12>
                                    <v-text-field
                                        v-model="additionalInformation"
                                        label="Additional information:"
                                        name="txtAdditional"
                                    ></v-text-field>
                                </v-flex>                            
                            </v-layout>
                            <div style="font-size: 10px; text-align: left">
                            * Mandatory field
                            </div>
                            <v-layout dense align-content-center justify-center wrap>
                                <v-btn  color="success" rounded @click="submit()" :disabled="!valid || captchaToken == ''">Submit</v-btn>
                            </v-layout>
                        </v-form>
                    </div>

                    <div style="height: 30px" v-if="true"></div>
                    <div class="iframe-container" v-if="false">
                        <youtube
                            style="border-radius: 15px"
                            :video-id="videoId"
                            :resize="true"
                            :fitParent="true"
                            :player-vars="playerVars"
                            ref="youtube"
                        ></youtube>
                    </div>
                    <div style="text-align: left" v-if="false">
                        * video from our previous program
                    </div>                    
                </template>
            </div>
        </div>
        <div style="height: 20px"></div>
        <div style="display: flex; flex-direction: row">
            <a href="https://www.facebook.com/SnooCare" target="_blank"
                ><v-img
                    :src="require('../assets/facebook-dark.png')"
                    width="32"
                    height="32"
                    contain
                ></v-img
            ></a>
            <div style="width: 10px"></div>
            <a
                href="https://www.youtube.com/channel/UC0URsp-xZ7-Cii93_U8FZqA"
                target="_blank"
                ><v-img
                    :src="require('../assets/youtube-dark.png')"
                    width="32"
                    height="32"
                    contain
                ></v-img
            ></a>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    mounted() {
        var self = this;
        this.$nextTick(() => {
            self.recaptcha();
            self.getForm();
        });
    },
    data() {
        return {
            videoId: "dk9jvUwJ-cw",
            playerVars: {
                controls: 1,
            },
            captchaToken: "",
            valid: false,
            fullname: "",
            clubName: "",
            email: "",
            country: "",
            numberOfTables: "",
            bandwidth: "",
            clubLink: "",
            additionalInformation: "",
            hasInternet: "",

            successSubmit: false,

            formData: {
                Name: "",
                Active: false,
                Header: "",
                Description: "",
                SuccessMessage: "",
                Questions: null,
            },

            loading: true

        };
    },
    computed: {
        ...mapGetters({
            currentDomain: "getCurrentDomain",
            rules: "getInputRules",
        }),
    },
    methods: {
        async recaptcha() {
            await this.$recaptchaLoaded()
            this.captchaToken = await this.$recaptcha('login')
        },
        getForm() {
            var self = this;

            var formId = this.$route.query.token;
            var options = {
                url: `${this.currentDomain}/get-form?id=${formId}`,
                method: 'GET',
                headers: 
                {
                    'content-type': 'application/json',
                    'accept': 'application/json',
                }
            };
            this.$http(options).then((response) => {
                console.log(response);
                self.formData.Name = response.body.Name;
                self.formData.Active = response.body.Active == 1;
                self.formData.Header = response.body.Header;
                self.formData.Description = response.body.Description;
                self.formData.SuccessMessage = response.body.SuccessMessage;


                self.loading = false;
            },
            response => {
                console.error(response.body);
                self.loading = false;
            });            
        },
        submit() {
            var self = this;
            var formId = this.$route.query.token;
            
            var content = JSON.stringify({
                "Full Name": this.fullname,
                "E-Mail": this.email,
                "Country": this.country,
                "Club Name": this.clubName,
                "Tables in Club": this.numberOfTables,
                "Internet": this.hasInternet,
                "Bandwidth": this.bandwidth,
                "Club Link": this.clubLink,
                "Additional Info": this.additionalInformation
            });

            var options = {
                url: `${this.currentDomain}/post-form`,
                method: 'POST',
                headers: 
                {
                    'content-type': 'application/json',
                    'accept': 'application/json',
                },
                body: {id: formId, content: content, captcha: this.captchaToken }
            };
            this.$http(options).then((response) => {
                if (response.status == 200) {
                    console.log(response);
                    self.successSubmit = true;
                }
            },
            response => {
                this.$store.dispatch("showMessageDialog", {Title: this.$t("dialog-titles.error"), Body: "Error during form submit.\nPlease try again", Type: "error" });
                self.recaptcha();
            });
        },
    },
    
};
</script>



<style>
#logo-container img {
    width: 100%;
    height: auto;
}
.iframe-container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
    background-color: black;
    border-radius: 15px;
}
.iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 3px 3px 8px #00000090;
}

.beta-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.demo-bg {
    opacity: 0.4;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: url("../assets/images/bg-element.png");
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
}
</style>