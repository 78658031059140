<template>
    <v-container style="padding-left: 10px; padding-right: 10px; padding-top: 0">
        <v-dialog v-model="showPolicy">
            <v-card>
                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                    style="color: black"
                >
                    {{ $t("pp" )}}
                </v-card-title>

                <v-card-text>
                    <div style="height: 400px; overflow-y: scroll">
                        <privacy-policy></privacy-policy>
                    </div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="showPolicy = false">
                        {{ $t("i-agree" )}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showTerms">
            <v-card>
                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                    style="color: black"
                >
                    {{ $t("tou" )}}
                </v-card-title>

                <v-card-text>
                    <div style="height: 400px; overflow-y: scroll">
                        <terms-of-use></terms-of-use>
                    </div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="showTerms = false">
                        {{ $t("i-agree" )}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>        
        <v-layout text-center wrap>
            <general-header></general-header>
        </v-layout>
        
        <template v-if="isLoading">
            <v-layout text-center wrap>
                <v-flex xs12>
                <v-card>        
                    <v-card-text class="text-lg-center headline">
                        <v-progress-circular
                        :width="5"
                        :size="150"
                        color="green"
                        indeterminate
                        style="font-size: 20px"
                        >
                        {{ $t("general.loading")}}
                        </v-progress-circular>
                    </v-card-text>
                </v-card>
                </v-flex>  
            
            </v-layout>
        </template>
        <template>
            <v-layout align-content-center justify-center wrap v-show="!isLoading">
                <v-stepper v-model="orderStep" vertical>
                    <v-stepper-step :complete="orderStep > 1" step="1">
                        {{ $t("buy-now.product-selection")}}
                    </v-stepper-step>
                    <v-stepper-content step="1">
                        <v-card color="transparent" class="mb-12" style="box-shadow: none; margin: 10px" fill-height fluid>
                            <v-row dense  align="start" justify="start">
                                <v-col class="d-flex" cols="3" sm="3" md="3" dense>
                                    <v-select dense solo rounded dark :items="[2, 3, 4, 5, 6, 7, 8, 9]" :rules="[rules.required]" v-model="orderQuantity"></v-select>                    
                                    <!-- <v-text-field v-model="orderQuantity" type="number" append-outer-icon="add" @click:append-outer="increment" prepend-icon="remove" @click:prepend="decrement"></v-text-field> -->
                                </v-col>
                                <v-col class="d-flex" cols="9" sm="9" md="9" dense style="margin-top: 7px">
                                    x {{ $t("buy-now.snoocare-system")}} (€{{ (shippingRates != null) ? shippingRates.unit_price_eur : -1 }})
                                </v-col>
                            </v-row>
                            <v-row dense  align="start" justify="start">
                                <v-col class="d-flex" cols="12" sm="12" md="12" dense>
                                    {{ $t("buy-now.subscription")}}: €{{ (shippingRates != null) ? shippingRates.subscription_eur : -1 }} {{ $t("buy-now.per-hour")}}
                                </v-col>
                            </v-row>                        
                        </v-card>
                        <v-btn color="success" rounded @click="orderStep = 2">
                            {{ $t("general.continue")}}
                        </v-btn>
                    </v-stepper-content>

                    <v-stepper-step :complete="orderStep > 2" step="2">
                        {{ $t("buy-now.shipping-details")}}
                    </v-stepper-step>
                    <v-stepper-content step="2">
                        <v-card color="transparent" class="mb-12" style="box-shadow: none">
                            <v-form  id="shippingDetailsForm" v-model="validShippingDetails" style="max-width: 500px; margin-top: 0px">                        
                                <v-text-field v-model="fullname" dense solo rounded  :rules="[rules.required]" :label="$t('customerRegistration.fullname')" name="txtFullName"></v-text-field>
                                <v-text-field v-model="email" dense solo rounded  :rules="[rules.email, rules.required]" :label="$t('customerRegistration.email')" name="txtEmail" ref="txtEmail"></v-text-field>
                                <vue-tel-input-vuetify dir="ltr" v-model="phoneTest" @input="onPhoneInput" :placeholder="$t('customerRegistration.phone')" :rules="[rules.required]"  solo dense rounded label="" :preferredCountries="['il', 'gb']" :valid-characters-only="true" ></vue-tel-input-vuetify>
                                <v-text-field v-model="address.line1" solo dense rounded :rules="[rules.required]" :label="$t('customerRegistration.address1')" name="txtLine1" ref="txtLine1"></v-text-field>
                                <v-text-field v-model="address.line2" solo dense rounded :label="$t('customerRegistration.address2')" name="txtLine2" ref="txtLine2"></v-text-field>
                                <v-row align="center" dense>
                                    <v-col class="d-flex" cols="6" sm="6" md="4" dense>
                                        <v-text-field v-model="address.city" solo dense rounded :rules="[rules.required]" :label="$t('customerRegistration.city')" name="txtCity" ref="txtCity"></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="6" sm="6" md="4" dense>
                                        <v-text-field v-model="address.zip" solo dense rounded :rules="[rules.required]" :label="$t('customerRegistration.zip')" name="txtZip" ref="txtZip"></v-text-field>                    
                                    </v-col>
                                    <v-col class="d-flex" cols="12" md="4" dense>
                                        <v-select dense solo rounded dark :items="countryCodeList" item-text="Name" item-value="Code" :rules="[rules.required]" v-model="address.country" :label="$t('customerRegistration.country')" :auto-select-first="true"></v-select>
                                    </v-col>
                                </v-row>
                            </v-form>                        
                        </v-card>
                        <v-btn color="success" rounded @click="gotToSummary()"  :disabled="!validShippingDetails || !phone.isValid">
                            {{ $t("general.continue")}}
                        </v-btn>
                        <v-btn rounded @click="orderStep = 1" style="margin-left: 10px; margin-right: 10px">
                            {{ $t("general.back")}}
                        </v-btn>
                    </v-stepper-content>

                    <v-stepper-step :complete="orderStep > 3" step="3">
                        {{ $t("purchasing.summary")}}
                    </v-stepper-step>
                    <v-stepper-content step="3">
                        <v-card color="transparent" class="mb-12" style="box-shadow: none; margin: 10px">
                            <v-form id="shippingDetailsForm" v-model="validPayment" style="max-width: 500px; margin-top: 10px">                        
                                <v-row>
                                    <table style="border-spacing: 10px;">
                                        <tr>
                                            <td>{{ $t("buy-now.snoocare-system")}}:</td>
                                            <td :align="$t('left-direction')" dir="ltr">{{ orderQuantity}} x €{{ (shippingRates != null) ? shippingRates.unit_price_eur : -1 }}</td>
                                        </tr>
                                        <tr>
                                            <td>{{ $t("purchasing.shipping")}}:</td>
                                            <td>€{{ totalPayment != null ? totalPayment.shipping.toFixed(2) : "" }}</td>
                                        </tr>
                                        <!-- <tr>
                                            <td style="display: inline-block; white-space: nowrap">
                                                <v-text-field v-model="couponCode" hide-details solo dense  label="Promo Code" name="txtPromo" ref="txtPromo" style="width: 160px; margin: 0px; padding: 0px"></v-text-field>
                                            </td>
                                            <td>
                                                <v-btn  @click="calculatePayment" style="margin-left: 0px; margin-right: 0px">
                                                    Apply
                                                </v-btn>
                                            </td>
                                        </tr> -->
                                        <tr v-if="totalPayment != null && totalPayment.couponValue != 0">
                                            <td colspan="2" style="color: lightgreen">
                                                <div v-if="totalPayment.couponValue < 0">Invalid promo code</div>
                                                <div v-if="totalPayment.couponValue > 0">Discount: {{ totalPayment.couponValue.indexOf("%") != -1 ? totalPayment.couponValue : '-€' + totalPayment.couponValue }}</div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="2" style="font-size: 8pt">
                                                {{ $t("purchasing.promo-hint")}}
                                            </td>
                                        </tr>
                                        <tr style="font-weight: bold">
                                            <td>{{ $t("purchasing.one-time")}}:</td>
                                            <td>€{{ totalPayment != null ? totalPayment.total.toFixed(2) : "" }}</td>
                                        </tr>
                                        <tr style="font-weight: bold">
                                            <td>{{ $t("buy-now.subscription")}}:</td>
                                            <td>€{{ (shippingRates != null) ? shippingRates.subscription_eur : -1 }} {{ $t("buy-now.per-hour")}}</td>
                                        </tr>
                                    </table>
                                </v-row>
                                <!-- <v-row>
                                    <v-col  cols="12">
                                        <div solo dense rounded id="card-element" name="card-element" ref="card-element"></div>
                                    </v-col>
                                </v-row> -->

                            </v-form>
                        </v-card>
                        <v-layout dense style="margin-top: -30px !important; display: flex; align-items: center">
                            <div>
                                <v-checkbox  v-model="agreeToTerms" dense :ripple="false">
                                    <template v-slot:label>
                                        <div>
                                        {{ $t('purchasing.i-agree-to') }}
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                            <a
                                                 @click="touClick();"
                                                v-on="on"
                                            >
                                                {{ $t("tou") }}
                                            </a>
                                            </template>
                                        </v-tooltip>
                                        {{ $t('purchasing.and') }}
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                            <a
                                                 @click="ppClick();"
                                                v-on="on"
                                            >
                                                {{ $t("pp") }}
                                            </a>
                                            </template>
                                        </v-tooltip>
                                        </div>
                                    </template>                                
                                </v-checkbox>
                            </div>
                        </v-layout>

                        <v-btn color="success" rounded :disabled="!agreeToTerms || disableButton" @click="proceedToCheckout">
                            {{ $t("purchasing.checkout")}}
                        </v-btn>
                        <v-btn rounded @click="orderStep = 2" style="margin-left: 10px; margin-right: 10px">
                            {{ $t("general.back")}}
                        </v-btn>
                    </v-stepper-content>
                </v-stepper>
                <!--
                <v-form id="nativeForm" v-model="valid" style="max-width: 500px; margin-top: 10px">
                    <v-text-field v-model="fullname" dense solo rounded  :rules="[rules.required]" :label="$t('customerRegistration.fullname')" name="txtFullName"></v-text-field>
                    <v-text-field v-model="email" dense solo rounded  :rules="[rules.email, rules.required]" :label="$t('customerRegistration.email')" name="txtEmail" ref="txtEmail"></v-text-field>
                    <vue-tel-input-vuetify dir="ltr" v-model="phoneTest" @input="onPhoneInput" :placeholder="$t('customerRegistration.phone')" :rules="[rules.required]"  solo dense rounded label="" :preferredCountries="['il', 'gb']" :valid-characters-only="true" ></vue-tel-input-vuetify>
                    <v-text-field v-model="address.line1" solo dense rounded :rules="[rules.required]" :label="$t('customerRegistration.address1')" name="txtLine1" ref="txtLine1"></v-text-field>
                    <v-text-field v-model="address.line2" solo dense rounded :label="$t('customerRegistration.address2')" name="txtLine2" ref="txtLine2"></v-text-field>
                    <v-row align="center" dense>
                        <v-col class="d-flex" cols="6" sm="6" md="4" dense>
                            <v-text-field v-model="address.city" solo dense rounded :rules="[rules.required]" :label="$t('customerRegistration.city')" name="txtCity" ref="txtCity"></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="6" sm="6" md="4" dense>
                            <v-text-field v-model="address.zip" solo dense rounded :rules="[rules.required]" :label="$t('customerRegistration.zip')" name="txtZip" ref="txtZip"></v-text-field>                    
                        </v-col>
                        <v-col class="d-flex" cols="12" md="4" dense>
                            <v-select dense solo rounded dark :items="countryCodeList" item-text="Name" item-value="Code" :rules="[rules.required]" v-model="address.country" :label="$t('customerRegistration.country')" :auto-select-first="true"></v-select>                    
                        </v-col>
                    </v-row>
                    <v-row align="center" dense v-if="loggedUser == null">
                        <v-col class="d-flex" cols="6" dense>
                            <v-text-field v-model="password" solo dense rounded :rules="[rules.required, rules.min]" type="password" :label="$t('enter-password')" name="txtPassword" ref="txtPassword"></v-text-field>
                        </v-col>
                        <v-col class="d-flex" cols="6" dense>
                            <v-text-field v-model="confirmPassword" solo dense rounded :rules="[rules.required, rules.min]" type="password" :label="$t('confirm-password')" name="txtConfirmPassword" ref="txtConfirmPassword"></v-text-field>
                        </v-col>
                    </v-row>force
                    <v-row>
                        <v-col  cols="12"  style="margin-top: -30px">
                            <div solo dense rounded id="card-element" name="card-element" ref="card-element"></div>
                        </v-col>
                    </v-row>

                    <v-layout dense style="margin-top: 30px !important; display: flex; align-items: center">
                        <div>
                            <v-checkbox  v-model="agreeToTerms" dense :ripple="false"></v-checkbox>
                        </div>
                        <div>
                            <span style="font-size: 10px" v-html="$t('i-agree-to')"></span><a style="font-size: 10px" @click="touClick();">{{ $t("tou") }}</a><span style="font-size: 10px" v-html="$t('and')"></span><a style="font-size: 10px" @click="ppClick();">{{ $t("pp") }}</a>
                        </div>
                    </v-layout>
                    <v-layout dense align-content-center justify-center wrap>
                        <v-btn  color="success" rounded @click="submit()" :disabled="!passwordOk || !agreeToTerms || !valid || !phone.isValid  || captchaToken == ''">{{ $t("customerRegistration.place-order")}}</v-btn>
                    </v-layout>
                </v-form>
                -->
            </v-layout>            
        </template>
        <div style="height: 100px;">&nbsp;</div>
    </v-container>
</template>

<script>

import { mapGetters } from 'vuex';

import PrivacyPolicy from '@/components/PrivacyPolicy';
import TermsOfUse from '@/components/TermsOfUse';

const countryList = require('country-list');

export default {
  components: {
    VueTelInput: async () => {
        const { VueTelInput } = await import('vue-tel-input');
        return VueTelInput;
    },
    'privacy-policy': PrivacyPolicy,
    'terms-of-use': TermsOfUse,
  },

    created () {
    },    
    mounted() {
        this.$vuetify.rtl = this.$t('rtl') == 'true';
        var self = this;
        this.$nextTick(() => {
            self.recaptcha();
            //self.getProducts();
            self.initPayment();
            self.getShippingRate();
            /*
            setInterval(function() {
                console.log(self.phoneTest);
            }, 1000);*/
        });
    },
    data () {
        return {
            showPolicy: false,
            showTerms: false,
            agreeToTerms: false,
            disableButton: false,
            orderStep: 1,
            stripe: null,
            stripeElements: null,
            stripeElementsStyles: {
                base: {
                    color: '#32325D',
                    fontWeight: 500,
                    fontSize: '16px',
                    fontSmoothing: 'antialiased',
                    '::placeholder': {
                        color: '#CFD7DF',
                    },
                    ':-webkit-autofill': {
                        color: '#e39f48',
                    },
                },
                invalid: {
                    color: '#E25950',
                    '::placeholder': {
                        color: '#FFCCA5',
                    },
                },
            },
            fullname: "",
            email: "",
            captchaToken: "",
            validPayment: false,
            validShippingDetails: false, 
            valid: false,
            phoneNew: null,
            phoneTest: null,
            password: "",
            confirmPassword: "",
            phone: {
                number: '',
                isValid: false,
                country: undefined,
            },
            address: {
                country: "",
                city: "",
                zip: "",
                line1: "",
                line2: ""
            },
            orderQuantity: 3,
            registrationCompleted: false,
            allProducts:[],
            selectedProduct: {
                name: "",
                image: "",
                price: 0,
                priceId: "",
                subscription: {
                    name: "",
                    price: 0,
                    priceId: ""
                },
                shipping: {
                    name: "",
                    image: "",
                    price: 0,
                    priceId: "",
                }
            },
            shippingRates: null,
            couponCode: "",
            totalPayment: null,
            oCustomer: null
            /*{
                "Zone21": [-1.0, 21.0, 28.0, 36.0, 44.0, 52.0, 58.0, 65.0, 70.0, 73.0, 76.0, 81.0, 86.0, 92.0, 97.0, 103.0, 108.0, 113.0],
                "Zone22": [-1.0, 43.5, 63.0, 78.0, 97.0, 105.0, 129.0, 136.0, 141.0, 145.0, 146.0, 147.0, 148.0, 150.0, 152.0, 155.0, 168.0, 176.0],
                "other": [-1.0, 52.0, 74.0, 96.0, 114.0, 133.0, 153.0, 175.0, 194.0, 211.0, 226.0, 231.0, 233.0, 235.0, 238.0, 241.0, 260.0, 269.0]
            },
            shippingCostFee: 1.19,
            shippingCostAdd: 5,
            usd2eur: 0.86,
            usd2eurDefault: 0.86
            */
        }
    },
    watch: {
        orderStep: function (newStep, oldStep) {
            if (oldStep == 2 && newStep == 3) {
                this.calculatePayment();
            }
        }
    },

    computed: {
        ...mapGetters({
            currentDomain: "getCurrentDomain",
            rules: "getInputRules",
            loggedUser: "getLoggedInUser"
        }),
        countryCodeList() {
            var list = countryList.getCodeList();
            const keys = Object.keys(list);
            var returnList = [];
            keys.forEach((key, index) => {
                returnList.push({ Name: list[key], Code: key.toUpperCase() });
            });
            returnList.sort((a, b) => (a.Name > b.Name) ? 1 : -1)

            return returnList;
        },
        passwordOk() {
            return (this.password != "" && this.confirmPassword != "");
        },
        isLoading() {
            return !(this.shippingRates != null && this.stripe != null);
        },
        estimatedShipping() {
            var value = "0";
            /*
            if (this.shippingRates != null) {
                var vol = Math.ceil((this.orderQuantity * 14 * 30 * 37) / 5000.0);
                
                value = ((this.shippingRates.rates["Zone21"][vol] * this.shippingRates.shippingCostFee + this.shippingRates.shippingCostAdd) * this.shippingRates.usd2eur).toFixed(2);
                console.log(`Vol: ${vol}, Value: ${value}`);
            }
            */
            return value;
        },
        getTotalPayment() {
            /*
            var shipping = parseFloat(this.estimatedShipping);
            var units = 0;
            if (this.shippingRates != null) {
                units = this.orderQuantity * this.shippingRates.unit_price;
            }
            */
            return 0; //shipping + units;
        }
    },
    methods: {
        gotToSummary() {
            this.oCustomer = {
                email: this.email,
                name: this.fullname,
                phone: this.phone.number.e164,
                address: {
                    city: this.address.city,
                    country: this.address.country,
                    line1: this.address.line1,
                    line2: this.address.line2,
                    postal_code: this.address.zip,
                    state: "",
                }
            };
            this.orderStep = 3;
            console.log(this.oCustomer);

        },
        increment () {
            this.orderQuantity = Math.min(parseInt(this.orderQuantity, 10) + 1, 4);
        },
        decrement () {
            this.orderQuantity = Math.max(parseInt(this.orderQuantity, 10) - 1, 2);
        },
        getShippingRate() {
            var self = this;

            var options = {
                url:  `${this.currentDomain}/payments/get-shipping-rates`,
                method: 'GET',
                headers: 
                {
                    'content-type': 'application/json',
                    'accept': 'application/json',
                    'Authorization': `Bearer ${this.loggedUser.token}`,
                }
            };
            this.$http(options).then((response) => {            
                if (response.status == 200) {
                    try {
                        self.shippingRates = response.body;
                        console.log(self.shippingRates);
                        self.$forceUpdate();
                    } catch (err) {
                        console.error(err);
                    }
                } else {
                    console.error("Cannot get shipping rates");
                }
            });
        },
        calculatePayment() {
            var self = this;
            var options = {
                url:  `${this.currentDomain}/payments/calculate-payment`,
                method: 'POST',
                headers: 
                {
                    'content-type': 'application/json',
                    'accept': 'application/json',
                    'Authorization': `Bearer ${this.loggedUser.token}`,
                },
                body: JSON.stringify({ "countryCode": this.address.country, "quantity": this.orderQuantity, "coupon": this.couponCode }),

            };
            this.$http(options).then((response) => {            
                if (response.status == 200) {
                    try {
                        console.log("8888888888888888888")
                        console.log(response.body);
                        console.log("8888888888888888888")
                        self.totalPayment = response.body;
                        console.log(self.totalPayment);
                        self.$forceUpdate();
                    } catch (err) {
                        console.error(err);
                    }
                } else {
                    console.error("Cannot calculate payment");
                }
            });


        },
        ppClick() {
            this.showPolicy = true;
        },
        touClick() {
            this.showTerms=  true;
        },
        initPayment() {
            var self = this;

            var options = {
                url: `${this.currentDomain}/payments/init-payment`,
                method: 'POST',
                headers: 
                {
                    'content-type': 'application/json',
                    'accept': 'application/json',
                }
            };
            this.$http(options).then((response) => {
                if (response.status == 200) {
                    var data =  response.body;
                    self.stripe = Stripe(data.pk);

                    // Create stripe elements object
                    //self.stripeElements = self.stripe.elements();
                    //var cardElement = self.stripeElements.create("card", { style: self.stripeElementsStyles });
                    //cardElement.mount("#card-element");

                    console.log("--- user ---")
                    console.log(self.loggedUser);
                    console.log("--- user ---")
                    
                    if (self.loggedUser != null) {
                        if (self.loggedUser.hasOwnProperty("stripe_customer") && self.loggedUser.stripe_customer != null && self.loggedUser.stripe_customer != "") {
                            var userOptions = {
                                url: `${this.currentDomain}/payments/get-customer-address`,
                                method: 'GET',
                                headers: 
                                {
                                    'content-type': 'application/json',
                                    'accept': 'application/json',
                                    Authorization: `Bearer ${self.loggedUser.token}`,

                                }
                            };
                            self.$http(userOptions).then((response) => {
                                if (response.status == 200) {

                                    var userAddress = response.body.address;
                                    self.address.country = userAddress.country;
                                    self.address.city = userAddress.city;
                                    self.address.zip = userAddress.postal_code;
                                    self.address.line1 = userAddress.line1;
                                    self.address.line2 = userAddress.line2;
                                    self.address.state = userAddress.state;

                                    self.fullname = self.loggedUser.name;
                                    self.email = self.loggedUser.email;
                                    self.phoneTest = self.loggedUser.phone;

                                    //self.$forceUpdate();
                                }
                            });

                            /*
                            self.stripe.customers.retrieve().then((customer) => {
                                console.log(customer);
                            });
                            */
                        }
                    }                    
                }
            });
        },
        getProducts() {
            var self = this;

            var options = {
                url: `${this.currentDomain}/payments/get-product-list`,
                method: 'GET',
                headers: 
                {
                    'content-type': 'application/json',
                    'accept': 'application/json',
                }
            };
            this.$http(options).then((response) => {
                if (response.status == 200) {
                    var data =  response.body;
                    self.stripe = Stripe(data.pk);
                    self.allProducts = data.products;
                    for (var i in self.allProducts) {
                        if (self.allProducts[i].price.type == "one_time" && self.allProducts[i].name != "Shipping") {
                            self.selectedProduct.name = self.allProducts[i].name;
                            self.selectedProduct.image = self.allProducts[i].image;
                            self.selectedProduct.price = self.allProducts[i].price.amount;
                            self.selectedProduct.priceId = self.allProducts[i].id;
                        } else if (self.allProducts[i].price.type == "one_time" && self.allProducts[i].name == "Shipping") {
                            self.selectedProduct.shipping.name = self.allProducts[i].name;
                            self.selectedProduct.shipping.image = self.allProducts[i].image;
                            self.selectedProduct.shipping.price = self.allProducts[i].price.amount;
                            self.selectedProduct.shipping.priceId = self.allProducts[i].id;
                        } else {
                            self.selectedProduct.subscription.name = self.allProducts[i].name;
                            self.selectedProduct.subscription.price = self.allProducts[i].price.amount;
                            self.selectedProduct.subscription.priceId = self.allProducts[i].id;
                        }
                    }

                }
            },
            response => {
                console.error(response.body);
            });
        },
        submit() {
            var self = this;

            var oCustomer = {
                email: this.email,
                name: this.fullname,
                phone: this.phone.number.e164,
                address: {
                    city: this.address.city,
                    country: this.address.country,
                    line1: this.address.line1,
                    line2: this.address.line2,
                    postal_code: this.address.zip,
                    state: "",
                }
            };

            if (this.password == "") {
                this.$store.dispatch("showMessageDialog", {Title: this.$t("dialog-titles.registration"), Body: this.$t("errors.registration-password-empty"), Type: "error" });
                return;
            } else if (this.password != this.confirmPassword) {
                this.$store.dispatch("showMessageDialog", {Title: this.$t("dialog-titles.registration"), Body: this.$t("errors.registration-password-confirm"), Type: "error" });
                return;
            }
            
            var options = {
                url: `${this.currentDomain}/payments/create-customer`,
                method: 'POST',
                headers: 
                {
                    'content-type': 'application/json',
                    'accept': 'application/json',
                },
                body: {Password: this.password, Captcha: this.captchaToken, Customer: oCustomer, Locale: this.$i18n.locale }
            };
            this.$http(options).then((response) => {
                if (response.status == 200) {
                    console.log("Go to login:");
                    //self.$store.commit("setLoggedInUser", response.body);
                    setTimeout(function() {
                        self.getPurchaseSession(oCustomer.email, response.body);
                    }, 500);
                }
            },
            response => {
                this.$store.dispatch("showMessageDialog", {Title: this.$t("dialog-titles.error"), Body: this.$t(`errors.${response.body}`), Type: "error" });
                self.recaptcha();
            });
        },
        getPurchaseSession(email, user) {
            var self = this;

            var selectedProducts = [{ "price": this.selectedProduct.priceId, quantity: 1}, {"price": this.selectedProduct.subscription.priceId, quantity: 1}, { "price": this.selectedProduct.shipping.priceId, quantity: 1}];
            var options = {
                url: `${this.currentDomain}/payments/create-payment-session`,
                method: 'POST',
                headers: 
                {
                    'content-type': 'application/json',
                    'accept': 'application/json',
                },
                body: JSON.stringify({ "items": selectedProducts, "customer": user.stripe_customer, "user_id": user.id, "name": user.name, "locale": this.$i18n.locale, "email": email }),
            };
            this.$http(options).then((response) => {
                if (response.status == 200) {
                    console.log(response);
                    self.stripe.redirectToCheckout({ sessionId: response.body.id });
                }
            },
            response => {
                this.$store.dispatch("showMessageDialog", {Title: this.$t("dialog-titles.error"), Body: this.$t(`errors.${response.body}`), Type: "error" });
            });
        },
        proceedToCheckout() {
            var self = this;
            self.disableButton = true;
            if (self.loggedUser.stripe_customer != "") {
                this.testNewPurchaseSession();
            } else {
                this.createNewCustomer();
            }
        },
        createNewCustomer() {
            var self = this;

            var options = {
                url: `${this.currentDomain}/payments/create-new-customer`,
                method: 'POST',
                headers: 
                {
                    'content-type': 'application/json',
                    'accept': 'application/json',
                    'Authorization': `Bearer ${self.loggedUser.token}`,
                },
                body: JSON.stringify({ "Customer": self.oCustomer, "Locale": this.$i18n.locale }),
            };
            this.$http(options).then((response) => {
                if (response.status == 200) {
                    if (response.body.Id != null && response.body.Id != undefined && response.body.Id != "") {
                        self.testNewPurchaseSession(response.body.Id);
                    }
                }
            },
            response => {
                this.$store.dispatch("showMessageDialog", {Title: this.$t("dialog-titles.error"), Body: this.$t(`errors.${response.body}`), Type: "error" });
                self.disableButton = false;
            });            
        },
        testNewPurchaseSession(customerId) {
            var self = this;

            var stripeCustomerId = self.loggedUser.stripe_customer;
            
            if (customerId != "") {
                stripeCustomerId = customerId;
            }

            

            var options = {
                url: `${this.currentDomain}/payments/create-new-payment-session`,
                method: 'POST',
                headers: 
                {
                    'content-type': 'application/json',
                    'accept': 'application/json',
                    'Authorization': `Bearer ${self.loggedUser.token}`,
                },
                body: JSON.stringify({ "quantity": self.orderQuantity, "customer": stripeCustomerId, "user_id": self.loggedUser.id, "name": self.loggedUser.name, "locale": this.$i18n.locale, "email": self.loggedUser.email }),
            };
            this.$http(options).then((response) => {
                if (response.status == 200) {
                    console.log(response);
                    self.stripe.redirectToCheckout({ sessionId: response.body.id });
                }
                self.disableButton = false;
            },
            response => {
                this.$store.dispatch("showMessageDialog", {Title: this.$t("dialog-titles.error"), Body: this.$t(`errors.${response.body}`), Type: "error" });
                self.disableButton = false;
            });
        },        
        async recaptcha() {
            await this.$recaptchaLoaded()
            this.captchaToken = await this.$recaptcha('login')
        },
        onPhoneInput(formattedNumber, { number, isValid, country }) {      
            this.phone.number = number;
            this.phone.isValid = isValid;
            this.phone.country = country && country.name;
        },    
    
    },
}
</script>