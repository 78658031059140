<template>
    <v-container style="padding-left: 10px; padding-right: 10px; padding-top: 0">
        <v-dialog v-model="showPolicy">
            <v-card>
                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                    style="color: black"
                >
                    {{ $t("pp" )}}
                </v-card-title>

                <v-card-text>
                    <div style="height: 400px; overflow-y: scroll">
                        <privacy-policy></privacy-policy>
                    </div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="showPolicy = false">
                        {{ $t("i-agree" )}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showTerms">
            <v-card>
                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                    style="color: black"
                >
                    {{ $t("tou" )}}
                </v-card-title>

                <v-card-text>
                    <div style="height: 400px; overflow-y: scroll">
                        <terms-of-use></terms-of-use>
                    </div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="showTerms = false">
                        {{ $t("i-agree" )}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>        
        <v-layout text-center wrap>
            <general-header></general-header>
        </v-layout>
        <template v-if="selectedProduct.name != ''">
            <v-layout align-content-center justify-center>
                {{ selectedProduct.name }}
            </v-layout>        
            <v-layout align-content-center justify-center>
                <v-img max-width="300" :src="selectedProduct.image" ></v-img>
            </v-layout>
            <v-layout style="font-size: 10pt; display: flex; align-items: center; justify-content: center; flex-direction: column ">
                <div>
                    <span>{{ $t('customerRegistration.one-time') }}</span> <span :style="'margin-' + $t('right-direction') + ':10px'">${{ selectedProduct.price }}</span>                    
                </div>
                <div>+</div>
                <div>
                    <span>${{ selectedProduct.subscription.price }}  <span :style="'margin-' + $t('left-direction') + ':5px'">{{ $t('customerRegistration.per-month') }}</span></span>                    
                </div>
                <!-- <span :style="'margin-' + $t('right-direction') + ':10px'">{{ $t('customerRegistration.one-time') }}</span> <span :style="'margin-' + $t('right-direction') + ':10px'">${{ selectedProduct.price }}</span>+<span :style="'margin-' + $t('left-direction') + ':10px'">${{ selectedProduct.subscription.price }}  <span :style="'margin-' + $t('left-direction') + ':5px'">{{ $t('customerRegistration.per-month') }}</span></span> -->
            </v-layout>        
        </template>
        <v-layout align-content-center justify-center wrap>
            <v-form id="nativeForm" v-model="valid" style="max-width: 500px; margin-top: 10px">
                <v-text-field v-model="fullname" dense solo rounded  :rules="[rules.required]" :label="$t('customerRegistration.fullname')" name="txtFullName"></v-text-field>
                <v-text-field v-model="email" dense solo rounded  :rules="[rules.email, rules.required]" :label="$t('customerRegistration.email')" name="txtEmail" ref="txtEmail"></v-text-field>
                <vue-tel-input-vuetify dir="ltr"  @input="onPhoneInput" :placeholder="$t('customerRegistration.phone')" :rules="[rules.required]"  solo dense rounded label="" :preferredCountries="['il', 'gb']" :valid-characters-only="true" ></vue-tel-input-vuetify>
                <v-text-field v-model="address.line1" solo dense rounded :rules="[rules.required]" :label="$t('customerRegistration.address1')" name="txtLine1" ref="txtLine1"></v-text-field>
                <v-text-field v-model="address.line2" solo dense rounded :label="$t('customerRegistration.address2')" name="txtLine2" ref="txtLine2"></v-text-field>
                <v-row align="center" dense>
                    <v-col class="d-flex" cols="6" sm="6" md="4" dense>
                        <v-text-field v-model="address.city" solo dense rounded :rules="[rules.required]" :label="$t('customerRegistration.city')" name="txtCity" ref="txtCity"></v-text-field>
                    </v-col>
                    <v-col class="d-flex" cols="6" sm="6" md="4" dense>
                        <v-text-field v-model="address.zip" solo dense rounded :rules="[rules.required]" :label="$t('customerRegistration.zip')" name="txtZip" ref="txtZip"></v-text-field>                    
                    </v-col>
                    <v-col class="d-flex" cols="12" md="4" dense>
                        <v-select dense solo rounded dark :items="countryCodeList" item-text="Name" item-value="Code" :rules="[rules.required]" v-model="address.country" :label="$t('customerRegistration.country')" :auto-select-first="true"></v-select>                    
                    </v-col>
                </v-row>
                <v-row align="center" dense>
                    <v-col class="d-flex" cols="6" dense>
                        <v-text-field v-model="password" solo dense rounded :rules="[rules.required, rules.min]" type="password" :label="$t('enter-password')" name="txtPassword" ref="txtPassword"></v-text-field>
                    </v-col>
                    <v-col class="d-flex" cols="6" dense>
                        <v-text-field v-model="confirmPassword" solo dense rounded :rules="[rules.required, rules.min]" type="password" :label="$t('confirm-password')" name="txtConfirmPassword" ref="txtConfirmPassword"></v-text-field>
                    </v-col>
                </v-row>

                <v-layout dense style="margin-top: -30px !important; display: flex; align-items: center">
                    <div>
                        <v-checkbox  v-model="agreeToTerms" dense :ripple="false"></v-checkbox>
                    </div>
                    <div>
                        <span style="font-size: 10px" v-html="$t('i-agree-to')"></span><a style="font-size: 10px" @click="touClick();">{{ $t("tou") }}</a><span style="font-size: 10px" v-html="$t('and')"></span><a style="font-size: 10px" @click="ppClick();">{{ $t("pp") }}</a>
                    </div>
                        <!-- <template v-slot:label style="white-space: nowrap">
                            
                        </template> -->
                </v-layout>
                <v-layout dense align-content-center justify-center wrap>
                    <v-btn  color="success" rounded @click="submit()" :disabled="!passwordOk || !agreeToTerms || !valid || !phone.isValid  || captchaToken == ''">{{ $t("customerRegistration.register")}}</v-btn>
                </v-layout>
            </v-form>
        </v-layout>
        <div style="height: 100px;">&nbsp;</div>
    </v-container>
</template>

<script>

import { mapGetters } from 'vuex';

import PrivacyPolicy from '@/components/PrivacyPolicy';
import TermsOfUse from '@/components/TermsOfUse';

const countryList = require('country-list');

export default {
  components: {
    VueTelInput: async () => {
        const { VueTelInput } = await import('vue-tel-input');
        return VueTelInput;
    },
    'privacy-policy': PrivacyPolicy,
    'terms-of-use': TermsOfUse,
  },

    created () {
    },    
    mounted() {
        this.$vuetify.rtl = this.$t('rtl') == 'true';
        var self = this;
        this.$nextTick(() => {
            self.recaptcha();
            self.getProducts();
        });
    },
    data () {
        return {
            showPolicy: false,
            showTerms: false,
            agreeToTerms: false,
            stripe: null,
            fullname: "",
            email: "",
            captchaToken: "",
            valid: false,
            phoneNew: null,
            password: "",
            confirmPassword: "",
            phone: {
                number: '',
                isValid: false,
                country: undefined,
            },
            address: {
                country: "",
                city: "",
                zip: "",
                line1: "",
                line2: ""
            },
            registrationCompleted: false,
            items: [
                {Name: this.$t("country-names.brazil"), Code: "BR"},
                {Name: this.$t("country-names.germany"), Code: "DE"},
                {Name: this.$t("country-names.ireland"), Code: "IE"},
                {Name: this.$t("country-names.india"), Code: "IN"},
                {Name: this.$t("country-names.israel"), Code: "IL"},
                {Name: this.$t("country-names.netherlands"), Code: "NL"},
                {Name: this.$t("country-names.nz"), Code: "NZ"},
                {Name: this.$t("country-names.serbia"), Code: "RS"},                
                {Name: this.$t("country-names.uk"), Code: "GB"}
            ],
            allProducts:[],
            selectedProduct: {
                name: "",
                image: "",
                price: 0,
                priceId: "",
                subscription: {
                    name: "",
                    price: 0,
                    priceId: ""
                },
                shipping: {
                    name: "",
                    image: "",
                    price: 0,
                    priceId: "",
                }
            }

        }
    },
    computed: {
        ...mapGetters({
            currentDomain: "getCurrentDomain",
            rules: "getInputRules",
            loggedUser: "getLoggedInUser"
        }),
        countryCodeList() {
            var list = countryList.getCodeList();
            const keys = Object.keys(list);
            var returnList = [];
            keys.forEach((key, index) => {
                returnList.push({ Name: list[key], Code: key.toUpperCase() });
            });
            returnList.sort((a, b) => (a.Name > b.Name) ? 1 : -1)

            console.log(returnList);
            return returnList;
        },
        passwordOk() {
            return (this.password != "" && this.confirmPassword != "");
        }
    },
    methods: {
        ppClick() {
            this.showPolicy = true;
        },
        touClick() {
            this.showTerms=  true;
        },
        getProducts() {
            var self = this;

            var options = {
                url: `${this.currentDomain}/payments/get-product-list`,
                method: 'GET',
                headers: 
                {
                    'content-type': 'application/json',
                    'accept': 'application/json',
                }
            };
            this.$http(options).then((response) => {
                if (response.status == 200) {
                    var data =  response.body;
                    self.stripe = Stripe(data.pk);                    
                    self.allProducts = data.products;
                    for (var i in self.allProducts) {
                        if (self.allProducts[i].price.type == "one_time" && self.allProducts[i].name != "Shipping") {
                            self.selectedProduct.name = self.allProducts[i].name;
                            self.selectedProduct.image = self.allProducts[i].image;
                            self.selectedProduct.price = self.allProducts[i].price.amount;
                            self.selectedProduct.priceId = self.allProducts[i].id;
                        } else if (self.allProducts[i].price.type == "one_time" && self.allProducts[i].name == "Shipping") {
                            self.selectedProduct.shipping.name = self.allProducts[i].name;
                            self.selectedProduct.shipping.image = self.allProducts[i].image;
                            self.selectedProduct.shipping.price = self.allProducts[i].price.amount;
                            self.selectedProduct.shipping.priceId = self.allProducts[i].id;
                        } else {
                            self.selectedProduct.subscription.name = self.allProducts[i].name;
                            self.selectedProduct.subscription.price = self.allProducts[i].price.amount;
                            self.selectedProduct.subscription.priceId = self.allProducts[i].id;
                        }
                    }
                }
            },
            response => {
                console.error(response.body);
            });
        },
        submit() {
            var self = this;

            var oCustomer = {
                email: this.email,
                name: this.fullname,
                phone: this.phone.number.e164,
                address: {
                    city: this.address.city,
                    country: this.address.country,
                    line1: this.address.line1,
                    line2: this.address.line2,
                    postal_code: this.address.zip,
                    state: "",
                }
            };

            if (this.password == "") {
                this.$store.dispatch("showMessageDialog", {Title: this.$t("dialog-titles.registration"), Body: this.$t("errors.registration-password-empty"), Type: "error" });
                return;
            } else if (this.password != this.confirmPassword) {
                this.$store.dispatch("showMessageDialog", {Title: this.$t("dialog-titles.registration"), Body: this.$t("errors.registration-password-confirm"), Type: "error" });
                return;
            }
            
            var options = {
                url: `${this.currentDomain}/payments/create-customer`,
                method: 'POST',
                headers: 
                {
                    'content-type': 'application/json',
                    'accept': 'application/json',
                },
                body: {Password: this.password, Captcha: this.captchaToken, Customer: oCustomer, Locale: this.$i18n.locale }
            };
            this.$http(options).then((response) => {
                if (response.status == 200) {
                    console.log("Go to login:");
                    //self.$store.commit("setLoggedInUser", response.body);
                    setTimeout(function() {
                        self.getPurchaseSession(oCustomer.email, response.body);
                    }, 500);
                }
            },
            response => {
                this.$store.dispatch("showMessageDialog", {Title: this.$t("dialog-titles.error"), Body: this.$t(`errors.${response.body}`), Type: "error" });
                self.recaptcha();
            });
        },
        getPurchaseSession(email, user) {
            var self = this;

            var selectedProducts = [{ "price": this.selectedProduct.priceId, quantity: 1}, {"price": this.selectedProduct.subscription.priceId, quantity: 1}, { "price": this.selectedProduct.shipping.priceId, quantity: 1}];
            var options = {
                url: `${this.currentDomain}/payments/create-payment-session`,
                method: 'POST',
                headers: 
                {
                    'content-type': 'application/json',
                    'accept': 'application/json',
                },
                body: JSON.stringify({ "items": selectedProducts, "customer": user.stripe_customer, "user_id": user.id, "name": user.name, "locale": this.$i18n.locale, "email": email }),
            };
            this.$http(options).then((response) => {
                if (response.status == 200) {
                    console.log(response);
                    self.stripe.redirectToCheckout({ sessionId: response.body.id });
                }
            },
            response => {
                this.$store.dispatch("showMessageDialog", {Title: this.$t("dialog-titles.error"), Body: this.$t(`errors.${response.body}`), Type: "error" });
            });
        },
        async recaptcha() {
            await this.$recaptchaLoaded()
            this.captchaToken = await this.$recaptcha('login')
        },
        onPhoneInput(formattedNumber, { number, isValid, country }) {      
            this.phone.number = number;
            this.phone.isValid = isValid;
            this.phone.country = country && country.name;
        },    
        
    
    },
}
</script>