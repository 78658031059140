<template>
  <v-container style="padding-left: 10px; padding-right: 10px; padding-top: 0">
    <general-header></general-header>
    <v-layout text-center wrap>
      <v-flex xs12>
        <v-text-field
          v-model="password"
          :append-icon="showPassword ? 'visibility' : 'visibility_off'"
          :rules="[rules.required, rules.min]"
          :type="showPassword ? 'text' : 'password'"
          name="txtPassword"
          label="Password:"
          @click:append="showPassword = !showPassword"
        ></v-text-field>
        <v-text-field
          v-model="confirmPassword"
          :append-icon="showConfirmPassword ? 'visibility' : 'visibility_off'"
          :rules="[rules.required, rules.min]"
          :type="showConfirmPassword ? 'text' : 'password'"
          name="txtConfirmPassword"
          label="Confirm Password:"
          @click:append="showConfirmPassword = !showConfirmPassword"
        ></v-text-field>        
        <v-btn color="success" @click="doResetPassword()">Reset password</v-btn>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  mounted() {
  },
  data() {
    return {
      showPassword: false,
      showConfirmPassword: false,
      password: "",
      confirmPassword: "",
      rules: {
        required: value => !!value || "Required.",
        counter: value => value.length <= 20 || "Max 20 characters",
        min: v => v.length >= 8 || "Min 8 characters",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      currentDomain: "getCurrentDomain",
      isLoggedIn: "isLoggedIn",
      loggedInUser: "getLoggedInUser"
    })
  },
  methods: {
    doResetPassword() {
        if (this.password == "" || this.confirmPassword == "")
        {
            this.$store.dispatch("showMessageDialog", {
              Title: "Reset Password",
              Body: "Please type your password in both fields",
              Type: "error"
            });
        }
        else if (this.password != this.confirmPassword)
        {
            this.$store.dispatch("showMessageDialog", {
              Title: "Reset Password",
              Body: "Passwords doesn't match",
              Type: "error"
            });
        }
        else
        {
            var self = this;
            this.$http.post(`${this.currentDomain}/reset-password`,
                    {
                        password: this.password,
                        token: this.$route.query.token
                    },
                    {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers":
                        "Origin, X-Requested-With, Content-Type, Accept"
                    }).then(response => {
                        if (response.status == 200) {
                        self.$store.commit("setLoggedInUser", response.body);
                        this.$router.push("my-account");
                        }
                    },
                    response => {
                        this.$store.dispatch("showMessageDialog", {
                        Title: "Reset Password",
                        Body: response.body,
                        Type: "error"
                        });
                    });
        }
    }
  }
};
</script>

<style>
</style>
