<template>
    <div style="flex-grow: 1; border-radius: 20px; margin: 10px;" :class="frameClass">
        <div class="player-name">
            {{ formattedPlayerName }}
            <div style="margin-top: 20px"><img :src="'http://' + gameServerAddress + ':1337/getimage?id=' + playerId" class="player-picture" /></div>
        </div>

        <div style="margin-top: 10px; font-size: 50pt">{{ framesWin }}</div>
        <div style="margin-top: 30px; font-size: 50pt">{{ isActive ? currentBreak : '&nbsp;' }}</div>
        <div style="margin-top: 30px; font-size: 50pt">
            {{ score }}
            <div style="font-size: 30px" v-if="ahead > 0" >
                Ahead: {{ ahead }}
            </div>
        </div>
        <div style="margin-top: 30px; display: flex; justify-content: center;">
            <div v-for="(count, index) in pottedBalls" :style="'display: flex; justify-content: center; position: relative; width:'+ ballSize + 'px; height:' + ballSize + 'px'"  v-show="count > 0" v-bind:key="'ball_'+ playerName + '_'+ index">
                <div :style="'position: absolute; left: 0; right: 0; top: 11px;  font-size: 14pt; font-weight: bold; color:'+ballData[index].TextColor + ';-webkit-text-stroke-width: 0.5px;  -webkit-text-stroke-color: ' + ballData[index].StrokColor">{{ count }}</div>
                <img :width="ballSize" :height="ballSize" :src="ballData[index].Image">
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import { eventBus} from '../main';
import { mapGetters } from 'vuex';

var moment = require('moment');

export default {
    props: ['playerId', 'playerName', 'isActive', 'framesWin', 'score', 'currentBreak', 'playerPicture', 'pottedBalls', 'ahead', 'redFrame'],
    data() {
        return {
            ballData: [
                { Image: require("../assets/images/balls-clean/red.png"), TextColor: "black", StrokColor: "white" },
                { Image: require("../assets/images/balls-clean/yellow.png"), TextColor: "black", StrokColor: "white" },
                { Image: require("../assets/images/balls-clean/green.png"), TextColor: "black", StrokColor: "white" },
                { Image: require("../assets/images/balls-clean/brown.png"), TextColor: "black", StrokColor: "white" },
                { Image: require("../assets/images/balls-clean/blue.png"), TextColor: "black", StrokColor: "white" },
                { Image: require("../assets/images/balls-clean/pink.png"), TextColor: "black", StrokColor: "white" },
                { Image: require("../assets/images/balls-clean/black.png"), TextColor: "white", StrokColor: "black" }
            ]
        };
    },
    computed: {
        ...mapGetters({
        }),
        frameClass() {
            if (this.isActive)
            {
                if (this.redFrame)
                {
                    return "active-player-red";
                }
                else
                {
                    return "active-player";
                }
            }
            else
            {
                return "inactive-player";
            }
        },
        ballSize() { return 60; },
        formattedPlayerName() {
            if (this.playerName.length > 12 && this.playerName.indexOf(' ') != -1)
            {
                var tmp = this.playerName.split(" ");
                var newName = tmp[0].charAt(0).toUpperCase() + ". " + tmp[1];
                return (newName > 12) ? tmp[1] : newName;
            }
            else 
                return this.playerName;
        },
        playerPictureInternal() {
            if (this.playerPicture != undefined && this.playerPicture != "")
            {
                return this.playerPicture;
            }
            else
            {
                var url = require("../assets/images/player-icon.png");
                return url;
            }
        }
    }
}
</script>