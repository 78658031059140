<template>
    <v-container style="padding-left: 10px; padding-right: 10px; padding-top: 0">
        <v-layout text-center wrap>
            <general-header></general-header>
        </v-layout>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    created () {
    },    
    mounted() {
        this.$vuetify.rtl = this.$t('rtl') == 'true';
        var self = this;
        this.$nextTick(() => {
        });
    },
    data () {
        return {    }
    }
}
</script>