<template>
    <v-container style="padding-left: 10px; padding-right: 10px; padding-top: 0">
        <v-layout text-center wrap>
            <general-header></general-header>
        </v-layout>
        
        <v-layout text-center wrap>
            <v-flex style="font-size: 16pt">
                {{ $t("payment-success.line1") }}
            </v-flex>
        </v-layout>
        <v-layout text-center wrap>
            <v-flex>
                {{ $t("payment-success.line2") }}
            </v-flex>
        </v-layout>
        <v-layout text-center wrap style="margin-top: 20px">
            <v-flex>
                <v-btn color="success"  @click="goToMyAccount()">{{ $t("payment-success.button") }}</v-btn>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    created () {
    },    
    mounted() {
        this.$vuetify.rtl = this.$t('rtl') == 'true';
        var self = this;
        this.$nextTick(() => {
        });
    },
    data () {
        return {    }
    },
    methods: {
        goToMyAccount() {
            this.$router.push("my-account");
        },
        
    }
}
</script>