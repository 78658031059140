<template>
    <v-container style="padding-left: 10px; padding-right: 10px; padding-top: 0">
        <general-header height="100" style></general-header>
        <v-layout text-center wrap v-if="!forgotPassword && !showCodeScreen" style="margin-top: 10pt">
            <v-flex xs12 v-if="showEmail">
                <v-text-field
                    dense solo rounded
                    v-model="email"
                    :rules="[rules.required, rules.email]"
                    :label="$t('email')+':'"
                    name="txtEmail"
                ></v-text-field>
            </v-flex>
            <v-flex xs12 v-if="showEmail">
                <v-text-field
                    dense solo rounded
                    v-model="password"
                    :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                    :rules="[rules.required, rules.min]"
                    :type="showPassword ? 'text' : 'password'"
                    name="txtPassword"
                    :label="$t('password')+':'"
                    @click:append="showPassword = !showPassword"
                ></v-text-field>
            </v-flex>
            <v-flex xs12 text-xs-right v-if="showEmail">
                <a style="color: #a6e5ff"  @click="showForgotPassword()">{{ $t("forgot-password") }}</a>                
            </v-flex>
            <v-flex xs12 v-if="showEmail" style="margin-top: 10px">
                <v-btn color="success" rounded @click="doLogin()">{{ $t("login") }}</v-btn>
            </v-flex>
         </v-layout>
        <v-layout text-center wrap v-if="showCodeScreen">
            <v-flex xs12>
                <v-text-field dense solo rounded style="width:100%" data-type="int" v-model="confirmCode" type="number" label="קוד כניסה" value="0" placeholder="הקלד את הקוד שקיבלת ב SMS"></v-text-field>
                <v-btn color="success" rounded @click="loginWithCode()">כניסה</v-btn>
                <br />
                <br />
                <a @click="gotToLogin()">חזור למסך כניסה</a>
            </v-flex>
        </v-layout>
        <v-layout text-center wrap v-if="forgotPassword">
            <v-flex xs12>
                <v-text-field
                    dense solo rounded
                    v-model="resetEmail"
                    :rules="[rules.required, rules.email]"
                    :label="$t('email')+':'"
                    name="txtEmail"
                ></v-text-field>
                <v-btn color="success" rounded @click="sendResetPassword()">{{ $t("reset-password") }}</v-btn>
                <br />
                <br />
                <a @click="gotToLogin()">{{ $t("back-to-login") }}</a>
            </v-flex>
        </v-layout>
        <div style="height: 30px"></div>
        <v-layout text-center wrap>
            <v-flex xs12>
                <v-row no-gutters dense>                        
                    <v-col cols="12">
                        {{ $t("dont-have") }}:
                        <br>
                        <div style="display: flex; justify-content: center">
                            <a href="https://apps.apple.com/us/app/snoocare/id1549386880" target="_blank"><img :src="require('../assets/images/apple-store-badge.png')" :style="{ height: badgeSize}" /></a>
                            <div style="width: 10px"></div>
                            <a href="https://play.google.com/store/apps/details?id=snoocare.pixellot.tv" target="_blank"><img :src="require('../assets/images/google-play-badge.png')" :style="{ height: badgeSize}" /></a>
                        </div>
                    </v-col>                            
                </v-row>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import VueTelInput from 'vue-tel-input';
import VueRecaptcha from 'vue-recaptcha';

export default {
    components: {
        VueTelInput: async () => {
            const { VueTelInput } = await import('vue-tel-input');
            return VueTelInput;
        },
        VueRecaptcha,

    },
    mounted() {
        var self = this;

        this.$vuetify.rtl = this.$t('rtl') == 'true';
        if (this.$route.query.email)
        {
            this.showEmail = true;
        }
        console.log(this.isLoggedIn + " | " + process.env.NODE_ENV);

        this.$nextTick(() => {
            self.recaptcha();
        });

        // Upon mounting of the component, we accessed the .bind({...})
        // function to put an initial image on the canvas.
        // var self = this;
        // setTimeout(function() {
        //   self.$refs.croppieRef.bind({
        //       viewport: 'circle'
        //   })
        // }, 1000);
    },
    data() {
        return {
            forgotPassword: false,
            showPassword: false,
            password: "",
            email: "",
            resetEmail: "",
            loginPhone: {number: "", isValid: false, country: ""},
            showCodeScreen: false,
            confirmCode: "",
            captchaToken: "",
            showEmail: true,



            // rules: {
            //   required: value => !!value || "Required.",
            //   counter: value => value.length <= 20 || "Max 20 characters",
            //   min: v => v.length >= 8 || "Min 8 characters",
            //   email: value => {
            //     const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            //     return pattern.test(value) || "Invalid e-mail.";
            //   }
            // }
        };
    },
    computed: {
        ...mapGetters({
            currentDomain: "getCurrentDomain",
            isLoggedIn: "isLoggedIn",
            loggedInUser: "getLoggedInUser",
            rules: "getInputRules"
        }),
        badgeSize() {
            const size = {xs:'40px',sm:'40px',lg:'60px',xl:'60px'}[this.$vuetify.breakpoint.name];
            return size ? [size] : '40px';
        },
    },
    methods: {
        showRefreshCAPTCHA(tmp) {
            console.log('CAPTCHA EXPIRED');
            this.refreshCaptcha = true;
        },
        verifiedCaptch(token) {
            this.captchaToken = token;
        },
        executeRecaptcha () {
            this.$refs.recaptcha.execute()
        },      
        async recaptcha() {
            await this.$recaptchaLoaded()
            this.captchaToken = await this.$recaptcha('login')
        },

        sendSMS()
        {
            var self = this;

            if (this.captchaToken == "")
            {
                this.$store.dispatch("showMessageDialog", {Title: "שגיאה", Body: 'יש לסמן: אני לא רובוט', Type: "error" });
                return;
            }

            if (this.loginPhone.isValid == false || this.loginPhone.number.e164 == "")
            {
                this.$store.dispatch("showMessageDialog", {
                    Title: "כניסה",
                    Body: "מספר טלפון לא תקין",
                    Type: "error"
                });
            }
            else
            {
            this.$http
                .post(
                    `${this.currentDomain}/send-sms`,
                    {
                        phone: self.loginPhone.number.e164, //.replace(/\s/g, ''),
                        captcha: self.captchaToken
                    },
                    {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers":
                        "Origin, X-Requested-With, Content-Type, Accept"
                    }
                )
                .then(
                    response => {
                        if (response.status == 200) {
                            self.showCodeScreen = true;
                        }
                    },
                    response => {
                        this.$store.dispatch("showMessageDialog", {
                            Title: self.$t("dialog-titles.error"),
                            Body: response.body,
                            Type: "error"
                        });
                    }
                );
            }
        },
        onLoginPhoneInput(formattedNumber, { number, isValid, country }) {      
            this.loginPhone.number = number;
            this.loginPhone.isValid = isValid;
            this.loginPhone.country = country && country.name;
        },
        showForgotPassword() {
            this.forgotPassword = true;
        },
        loginWithCode()
        {
            var self = this;
            if (this.confirmCode == "")
            {
                this.$store.dispatch("showMessageDialog", {
                    Title: "כניסה",
                    Body: "יש להקליד את קוד הכניסה",
                    Type: "error"
                });
            }
            else
            {
            this.$http
                .post(
                    `${this.currentDomain}/sms-login`,
                    {
                        phone: this.loginPhone.number.e164,
                        confirmCode: this.confirmCode
                    },
                    {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers":
                            "Origin, X-Requested-With, Content-Type, Accept"
                    }
                )
                .then(
                    response => {
                        if (response.status == 200) {
                            self.showCodeScreen = false;
                            self.$store.commit(
                                "setLoggedInUser",
                                response.body
                            );
                            this.$router.push(this.$route.query.redirect || 'mainmenu');
                            //this.$router.push("mainmenu");
                        }
                    },
                    response => {
                        this.$store.dispatch("showMessageDialog", {
                            Title: self.$t("dialog-titles.error"),
                            Body: response.body,
                            Type: "error"
                        });
                    }
                );
            }
        },
        gotToLogin() {
            this.forgotPassword = false;
            this.showCodeScreen = false;
        },
        sendResetPassword() {
            var self = this;

            if (this.resetEmail != "") {
                var self = this;
                this.$http
                    .post(
                        `${this.currentDomain}/send-reset-password`,
                        {
                            email: this.resetEmail
                        },
                        {
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Headers":
                                "Origin, X-Requested-With, Content-Type, Accept"
                        }
                    )
                    .then(
                        response => {
                            if (response.status == 200) {
                                this.$store.dispatch("showMessageDialog", {
                                    Title: self.$t("reset-password"),
                                    Body:
                                        self.$t("reset-password-message"),
                                    Type: "success"
                                });
                            }
                        },
                        response => {
                            this.$store.dispatch("showMessageDialog", {
                                Title: self.$t("dialog-titles.error"),
                                Body: response.body,
                                Type: "error"
                            });
                        }
                    );
            } else {
                var msg = "Unknow error";
                if (this.resetEmail == "") {
                    msg = self.$t("reset-password-enter-email");
                }
                this.$store.dispatch("showMessageDialog", {
                    Title: self.$t("reset-password"),
                    Body: msg,
                    Type: "error"
                });
            }
        },
        doLogin() {
            var self = this;
            this.$http
                .post(
                    `${this.currentDomain}/login`,
                    {
                        password: this.password,
                        email: this.email
                    },
                    {
                        "Access-Control-Allow-Origin": "*",
                        "Access-Control-Allow-Headers":
                            "Origin, X-Requested-With, Content-Type, Accept"
                    }
                )
                .then(
                    response => {
                        if (response.status == 200) {
                            self.$store.commit(
                                "setLoggedInUser",
                                response.body
                            );
                            this.$router.push(this.$route.query.redirect || 'my-account' || 'dashboard');
                            //this.$router.push("mainmenu");
                        }
                    },
                    response => {
                        this.$store.dispatch("showMessageDialog", {
                            Title: self.$t("dialog-titles.error"),
                            Body: self.$t("errors." + response.body),
                            Type: "error"
                        });
                    }
                );
        },
        goToRegistration() {
            this.$router.push("register");
        },
        goToNewGame() {
            this.$router.push("newgamev2");
        }

    }
};
</script>

<style>
</style>
