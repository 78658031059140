<template>
    <div style="display: flex; height: 100vh; flex-direction: column; justify-content: center; align-items: center">
        <div style="align-self: center;">
            <v-img src="../assets/images/soon.png"   max-height="342"  max-width="693" contain></v-img>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'Home',
}
</script>
