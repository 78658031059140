<template>
    <v-layout text-center wrap  style="margin: 0px; padding: 0px; align-items: center">
        <template v-if="standardLayout">
            <v-flex xs12 style="margin: 0px; padding: 0px;">
                <v-img :src="require('../assets/beta.png')" class="my-3" contain :height="logoHeight"></v-img>
            </v-flex>
        </template>
        <template v-if="!standardLayout">
            <v-flex xs8 text-xs-right style="" display-1>
                ניהול מועדון {{ clubName }}
            </v-flex>
            <v-flex xs4 style="margin: 0px; padding: 0px;" >
                <v-img position="left center" :src="require('../assets/logo-main.png')" class="my-3" contain :height="logoHeight"></v-img>
            </v-flex>
        </template>
    </v-layout>
</template>

<script>
export default {
    props: ['margin', 'height', 'clubName'],
    data: function () {
        return {
            standardLayout: (this.clubName == undefined) ? true : false
        }
    },
    computed: {
        logoHeight() {
            return (this.height == undefined) ? 130 : this.height;
        }
    }

};
</script>
