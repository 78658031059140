<template>
    <v-dialog v-model="show" fullscreen hide-overlay Overflowed  transition="dialog-bottom-transition" v-if="historyData != null" style="-webkit-overflow-scrolling: touch; overflow-y: scroll; z-index: 99">
        <v-card>
            <v-toolbar dark color="primary">
            <v-btn icon dark @click.native="show = false">
                <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>היסטוריית משחק</v-toolbar-title>
            <v-spacer></v-spacer>
            </v-toolbar>
            <v-container class="history-title">
                <v-layout style="padding: 5pt;">
                    <v-flex subheading xs6 text-center>{{ players[Object.keys(players)[0]].Name }}</v-flex>
                    <v-flex subheading xs6 text-center>{{ players[Object.keys(players)[1]].Name }}</v-flex>
                </v-layout>
            </v-container>
            <v-container style="width: 100%;  -webkit-overflow-scrolling: touch; overflow-y: scroll" v-if="inGame">
                <v-timeline  clipped>
                    <v-timeline-item
                    v-for="(entry, index) in historyData"
                    :key="'entry-' + index"
                    class="mb-3"
                    :color="scoreColor[entry.Score]"
                    small
                    :left="entry.PlayerName == players[Object.keys(players)[0]].Name"
                    :right="entry.PlayerName == players[Object.keys(players)[1]].Name"
                    >
                    <template v-slot:icon>
                        <img width="64" :src="require(`../assets/images/balls-clean/${balls[entry.Score]}`)">
                    </template>

                    <v-layout justify-space-between row wrap >
                        <v-flex xs12 v-text="entry.TimeString" font-weight-black></v-flex>
                        <v-flex xs12 font-weight-thin v-html="getHistoryEntryText(entry)"></v-flex>
                    </v-layout>
                    </v-timeline-item>
                </v-timeline>

                <!-- </v-layout> -->
            </v-container>

            <v-container id="historyContainer" style="width: 100%;  -webkit-overflow-scrolling: touch; overflow-y: scroll" v-if="!inGame">
                <template  v-for="(frame, frameIndex) in historyData">
                    <div align="center" :key="`frame-${frameIndex}`">פריים {{ frameIndex + 1  }}</div>
                    <v-timeline  clipped :key="`timeline-${frameIndex}`">
                        <v-timeline-item 
                        v-for="(entry, index) in frame"
                        :key="'entry-' + index"
                        class="mb-3"
                        :color="scoreColor[entry.Score]"
                        small
                        :left="entry.PlayerName == players[Object.keys(players)[0]].Name"
                        :right="entry.PlayerName == players[Object.keys(players)[1]].Name"
                        >
                        <template v-slot:icon>
                            <img width="64" :src="require(`../assets/images/balls-clean/${balls[entry.Score]}`)">
                        </template>

                        <v-layout justify-space-between row wrap >
                            <v-flex xs12 v-text="entry.TimeString" font-weight-black></v-flex>
                            <v-flex xs12 font-weight-thin v-html="getHistoryEntryText(entry)"></v-flex>
                        </v-layout>
                        </v-timeline-item>
                    </v-timeline>

                </template>
            </v-container>

        </v-card>
    </v-dialog>
</template>
<script>
import { eventBus} from '../main';
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash'

var moment = require('moment');


export default {
    props: {
        inGame: {
            default: false,
            type: Boolean
        },
        gameId: String,
        frame: Number
    },

    // props: ['gameId', 'frame', 'inGame'],
    data() {
        return {
            show: false,
            historyData: null,
            players: null,
            //show: false,
            scoreColor: ["#ffffff", "#d70002", "#fad743", "#048301", "#b65e05", "#0a59ed", "#ff99b0", "#313131"],
            balls: ["white.png", "red.png", "yellow.png", "green.png", "brown.png", "blue.png", "pink.png", "black.png"],
        }
    },
    computed: {
        ...mapGetters({
            loggedInUser: "getLoggedInUser",
            currentDomain: "getCurrentDomain"
        }),
   
    },
    mounted() {
        //var elm = document.getElementById("historyContainer");
        //elm.style.height = (window.innerHeight - elm.getBoundingClientRect().top - 10) + 'px';
    },
    created () {

    },
    watch: {
        // show: function (val) {
        //     this.internalShow = val;
        //     if (val)
        //     {
        //         this.loadGameHistory();
        //     }
        //     console.log("Val = " + val)
        // },
    },
    methods: {
        millisToTime(ms) {
            return moment('2000-01-01 00:00:00').add(moment.duration(ms)).format('HH:mm:ss');            
        },           
        loadGameHistory()
        {
            if (this.gameId == "")
                return;

            console.log("Loading history");
            this.show = true;

            var self = this;
            this.historyData = null;
            this.$http.get(`${self.currentDomain}/get-game-entries`,
            {
                params: { gameId: self.gameId}
            }, 
            { headers: { 'Authorization': `Bearer ${self.loggedInUser.token}`} })
            .then(
                response => {
                    if (response.status == 200) {
                        
                        console.log(response.body);
                        self.players = response.body.Players;
                        if (self.inGame)
                        {
                            if (response.body.Entries[self.frame] != undefined)
                            {
                                var tmpHistory = [];
                                for (var entryIdx in response.body.Entries[self.frame].Entries)
                                {
                                    const entry = response.body.Entries[self.frame].Entries[entryIdx];
                                    const time = (entry.Timestamp - response.body.Entries[self.frame].StartDate);
                                    tmpHistory.push({ Id: entryIdx, 
                                                        Time: time,
                                                        TimeString: self.millisToTime(time),
                                                        Score: entry.Score, 
                                                        PlayerName: response.body.Players[entry.PlayerId].Name,
                                                        Foul: entry.Foul,
                                                        FoulWithRed: entry.FoulWithRed,
                                                        Freeball: entry.Freeball,
                                                        TotalScore: entry.TotalScore,
                                                        Break: entry.Break });
                                }
                                console.log(tmpHistory);
                                tmpHistory.reverse();
                                self.historyData = tmpHistory;
                                // console.log(response.body);
                            }
                        }
                        else
                        {
                            var tmpHistory = [];
                            for (var frameIdx in response.body.Entries)
                            {
                                var frameEntries = [];
                                for (var entryIdx in response.body.Entries[frameIdx].Entries)
                                {
                                    const entry = response.body.Entries[frameIdx].Entries[entryIdx];
                                    const time = (entry.Timestamp - response.body.Entries[frameIdx].StartDate);
                                    frameEntries.push({ Id: entryIdx, 
                                                        Time: time,
                                                        TimeString: self.millisToTime(time),
                                                        Score: entry.Score, 
                                                        PlayerName: response.body.Players[entry.PlayerId].Name,
                                                        Foul: entry.Foul,
                                                        FoulWithRed: entry.FoulWithRed,
                                                        Freeball: entry.Freeball,
                                                        TotalScore: entry.TotalScore,
                                                        Break: entry.Break });
                                }
                                tmpHistory.push(cloneDeep(frameEntries));
                            }
                            console.log(tmpHistory);
                            self.historyData = cloneDeep(tmpHistory);
                            // console.log(response.body);
                        }
                    }
                },
                response => {
                    console.error(response.body);
                }
            );   
        },
        getHistoryEntryText(entry)
        {
            var str = "";
            var score = entry.Score;
            if (entry.Foul == 1 || entry.FoulWithRed == 1)
            {
                str = "<span style='font-weight: bold; color: #FF9800'>עבירה</span> של ";
                score = Math.max(4, score);
            }
            else
            {
                str = "";
            }
            str += score + " נקודות";
            if (entry.FoulWithRed)
                str += " עם כדור אדום שנכנס";

            if (entry.Freeball)
                str += "<span style='font-weight: bold; color: #0dff4a'> ככדור חופשי</span>";

            str += "<br>ניקוד כולל: " + entry.TotalScore;
            str += "<br>ברייק: " + entry.Break;

            return str;
            
        }        
    }
    
}
</script>