<template>
    <v-app id="inspire">
        <v-dialog v-model="pleaseWait" persistent width="400" >
            <v-container grid-list-md text-center>
                <v-layout row wrap>
                <v-flex xs12>
                <v-card>        
                    <v-card-text class="text-lg-center headline" dir="ltr">
                        Please wait...<br/><br/>
                    <v-progress-circular :size="90" :width="7" color="#079038" indeterminate></v-progress-circular>
                    </v-card-text>
                </v-card>
                </v-flex>      

                </v-layout>
            </v-container>
        </v-dialog>

        <v-navigation-drawer v-model="drawer" app >
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="title" style="display: flex">
                            <!-- <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon> -->
                            <v-img
                                :src="require('../assets/logo-main.png')"
                                contain
                                max-width=150
                                @click="drawer = !drawer"
                            ></v-img>
                        </v-list-item-title>
                        <v-list-item-subtitle style="margin-top: 5px">
                            {{ loggedInUser.name }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list dense nav>
                    <template v-if="hasPermissions">
                        <v-list-item v-for="item in menuItems" :key="item.title" link  @click="activeSection = item.section">
                            <v-list-item-icon>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{
                                    item.title
                                }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>

                    <v-list-item link @click="doLogout">
                        <v-list-item-icon>
                            <v-icon>mdi-logout</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("logout")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>                    
                </v-list>
        </v-navigation-drawer>

        <v-app-bar app>
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title style="margin:0px; padding: 0px;">
                <v-img v-if="!drawer"
                    :src="require('../assets/logo-main.png')"
                    contain
                    style="max-width: 150px"
                ></v-img>
            </v-toolbar-title>
        </v-app-bar>

        <v-main style="margin: 0px; padding: 20px" v-if="hasPermissions">
            <v-flex align-self-start v-if="activeSection == 'dashboard'">
                <v-layout wrap column class="fill-height">
                    <div style="font-size: 24pt">Dashboard</div>
                    <v-divider></v-divider>                    

                    <v-container class="py-0 px-0" fluid>
                        <v-row no-gutters dense>                        
                            <v-col cols="12" :style="{fontSize: titleTextSize}">
                                Download SnooCare App:
                            </v-col>                            
                        </v-row>
                    </v-container>
                </v-layout>
            </v-flex> <!-- DASHBOARD -->

            <v-flex align-self-start v-if="activeSection == 'users'">
                <v-layout wrap column class="fill-height">
                    <v-container class="py-0 px-0" fluid>
                        <v-row no-gutters dense>                        
                            <v-col cols="12" :style="{fontSize: titleTextSize}">
                                <v-data-table 
                                        :headers="usersHeaders" 
                                        :items="userList" 
                                        :search="userSearch" 
                                        :single-expand="true"
                                        :expanded.sync="userExpanded"
                                        item-key="Id"
                                        show-expand
                                >
                                    <template v-slot:top>
                                        <v-toolbar flat>
                                            <v-toolbar-title style="width: 100%">
                                                <div style="display: flex; justify-content: space-between;">
                                                    <div>Users</div>
                                                    <div></div>
                                                    <div style="margin-left: 20px">
                                                        <v-text-field v-model="userSearch" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
                                                    </div>
                                                </div>
                                            </v-toolbar-title>
                                            
                                            <v-spacer></v-spacer>
                                        </v-toolbar>
                                    </template>
                                    <template v-slot:item.actions="{ item }">
                                        <v-menu offset-y>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn dark icon v-bind="attrs" v-on="on">
                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item :disabled="item.IsConfirmed" style="cursor: pointer" @click="Activate(item.Id)">
                                                    <v-list-item-title>Activate</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item style="cursor: pointer" @click="Activate(item.Id)">
                                                    <v-list-item-title>Send Password Reset</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </template>                                    
                                    <template v-slot:item.Name="{ item }">
                                        {{ item.Name }}
                                        <v-icon  v-if="item.IsConfirmed == 1" color="green">
                                            mdi-check-circle
                                        </v-icon>
                                        <v-icon  v-if="item.StripeCustomer != null && item.StripeCustomer != ''" color="green">
                                            mdi-currency-usd
                                        </v-icon>
                                    </template>

                                    <template v-slot:expanded-item="{ headers, item }">
                                    <td :colspan="headers.length" style="background-color: #707070; padding: 10px">
                                        <v-simple-table v-if="!forMobile">
                                            <thead>
                                                <tr>
                                                    <th width="50%" class="text-left">ID</th>
                                                    <th width="40%" class="text-left">Stripe</th>
                                                    <th width="5%" class="text-left">Last Login</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{{ item.Id }}</td>
                                                    <td><a  v-if="item.StripeCustomer != null && item.StripeCustomer != ''" target="_" :href="getStripeCustomerURL(item.StripeCustomer)">{{ item.StripeCustomer }}</a></td>
                                                    <td>{{ item.LastLoginDate }}</td>
                                                </tr>
                                            </tbody>
                                        </v-simple-table>
                                        <div v-if="forMobile" class="theme--dark v-sheet" style="padding: 10px">
                                            <div>ID: {{ item.Id }}</div>
                                            <div v-if="item.StripeCustomer != null && item.StripeCustomer != ''">Stripe: <a target="_" :href="getStripeCustomerURL(item.StripeCustomer)">{{ item.StripeCustomer }}</a></div>
                                            <div>Last Login: {{ item.LastLoginDate }}</div>
                                        </div>
                                    </td>
                                    </template>

                                </v-data-table>
                            </v-col>                            
                        </v-row>
                    </v-container>
                </v-layout>
            </v-flex> <!-- Users -->

            <v-flex align-self-start v-if="activeSection == 'clubs'">
                <v-layout wrap column class="fill-height">
                    <div style="font-size: 24pt">Clubs</div>
                    <v-divider></v-divider>                    

                    <v-container class="py-0 px-0" fluid>
                        <v-row no-gutters dense>                        
                            <v-col cols="12" :style="{fontSize: titleTextSize}">
                                <v-simple-table>
                                    <!-- <template v-slot:default> -->
                                        <thead>
                                            <tr>
                                                <th width="50%" class="text-left">Name</th>
                                                <th width="40%" class="text-left">Owner</th>
                                                <th width="5%" class="text-left">Private User</th>
                                                <th width="5%" class="text-left">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template  v-for="(club, i) in clubList">
                                            <tr :key="club.Id"  >
                                                <td>{{ club.Name }} <span style="font-size:9px">({{ club.Id }})</span></td>
                                                <td>{{ club.OwnerName }}</td>
                                                <td>{{ club.IsPrivate == 1 ? "Yes" : "No" }}</td>
                                                <td style="display: flex; align-items: center">
                                                    <v-menu offset-y>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn dark icon v-bind="attrs" v-on="on">
                                                                <v-icon>mdi-dots-vertical</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-list>
                                                            <v-list-item style="cursor: pointer">
                                                                <v-list-item-title><a :href="'my-account?id='+ club.Id" target="_">Go To Club</a></v-list-item-title>
                                                            </v-list-item>
                                                        </v-list>
                                                    </v-menu>
                                                    <!-- <v-btn dark icon @click="expandRow(i)">
                                                        <v-icon v-if="selectedExpandRow == i">mdi-chevron-up</v-icon>
                                                        <v-icon v-if="selectedExpandRow != i">mdi-chevron-down</v-icon>
                                                    </v-btn> -->
                                                </td>
                                            </tr>
                                            </template>
                                        </tbody>
                                    <!-- </template> -->
                                </v-simple-table>
                            </v-col>                            
                        </v-row>
                    </v-container>
                </v-layout>
            </v-flex> <!-- Clubs -->            

      
        </v-main>
    </v-app>
</template>

<script>
import { eventBus } from "../main";
import { mapGetters } from "vuex";
const cloneDeep = require('clone-deep');

export default {
    data() {
        return {
            drawer: true,
            mini: false,
            menuItems: [
                { title: "Dashboard", icon: "mdi-view-dashboard", section: "dashboard" },
                { title: "Users", icon: "mdi-account-multiple", section: "users" },
                /*{ title: "Tables", icon: "mdi-lifebuoy", section: "tables" },*/
                { title: "Clubs", icon: "mdi-billiards-rack", section: "clubs" },
                /*{ title: "About", icon: "mdi-help-box" },*/
            ],
            activeSection: "users",
            pleaseWait: false,

            usersHeaders: [
                { text: 'Name', align: 'start', sortable: true, value: 'Name' },
                { text: 'Email', value: 'EMail' },
                { text: 'Phone', value: 'Phone' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            userList: [],
            tableList: [],
            clubList: [],
            userExpanded: [],
            userSearch: "",
            userSingleExpand: true,
            selectedExpandRow: -1

        };
    },
    computed: {
        ...mapGetters({
            loggedInUser: "getLoggedInUser",
            currentDomain: "getCurrentDomain",
        }),
        hasPermissions() {
            return (this.loggedInUser.isSuper == true)
        },
        forMobile() {
            const result = { xs: true, sm: true, lg: false, xl: false}[this.$vuetify.breakpoint.name];
            return result;

        },
        size () {
            const size = {xs:'small',sm:'small', lg:'',xl:''}[this.$vuetify.breakpoint.name];
            return size ? { [size]: true } : {}
        },
        badgeSize() {
            const size = {xs:'40px',sm:'40px',lg:'60px',xl:'60px'}[this.$vuetify.breakpoint.name];
            return size ? [size] : '40px';
        },
        titleTextSize() {
            const size = {xs:'12pt',sm:'12pt',lg:'14pt',xl:'14pt'}[this.$vuetify.breakpoint.name];
            return size ? [size] : '14pt';
        },
        normalTextSize() {
            const size = {xs:'10pt',sm:'10pt',lg:'12pt',xl:'12pt'}[this.$vuetify.breakpoint.name];
            return size ? [size] : '12pt';
        },
    },
    mounted() {
        var self = this;
        this.$vuetify.rtl = this.$t("rtl") == "true";
        this.$nextTick(() => {
            self.getAllUsers();
            self.getAllClubs();
            console.log(self.loggedInUser);
        });
    },
    created () {
        var self = this;
    },
    watch: {},
    methods: {
        refreshTable(publicId) {
            this.$store.dispatch('sendMessage', JSON.stringify({ Type: "ClubAdmin", Action: "RELOAD_PAGE", TablePublicId: publicId}));
        },        
        activateUser() {

        },
        getStripeCustomerURL(stripeId) {
            var url = "https://dashboard.stripe.com/";
            if (this.currentDomain.indexOf("dev.snoocare") > -1) {
                url += "test/";
            }
            url += "customers/" + stripeId;
            return url;
        },
        doLogout() {
            this.$store.dispatch("doLogOut");
        },
        getAllUsers() {
            var self = this;

            var options = {
                url: `${this.currentDomain}/admin/get-all-users`,
                method: "GET",
                headers: {
                    "content-type": "application/json",
                    accept: "application/json",
                    Authorization: `Bearer ${this.loggedInUser.token}`,
                },
            };
            this.$http(options).then(
                (response) => {
                    if (response.status == 200) {
                        self.userList = response.body;
                    }
                },
                (response) => {
                    console.error(response.body);
                }
            );            
        },
        getAllTables() {
            var self = this;

            var options = {
                url: `${this.currentDomain}/admin/get-all-tables`,
                method: "GET",
                headers: {
                    "content-type": "application/json",
                    accept: "application/json",
                    Authorization: `Bearer ${this.loggedInUser.token}`,
                },
            };
            this.$http(options).then(
                (response) => {
                    if (response.status == 200) {
                        self.tableList = response.body;
                    }
                },
                (response) => {
                    console.error(response.body);
                }
            );            
        },
        getAllClubs() {
            var self = this;

            var options = {
                url: `${this.currentDomain}/admin/get-all-clubs`,
                method: "GET",
                headers: {
                    "content-type": "application/json",
                    accept: "application/json",
                    Authorization: `Bearer ${this.loggedInUser.token}`,
                },
            };
            this.$http(options).then(
                (response) => {
                    if (response.status == 200) {
                        self.clubList = response.body;
                    }
                },
                (response) => {
                    console.error(response.body);
                }
            );            
        },        
    },
};
</script>
