<template>
    <div class="beta-page">
        <div class="demo-bg"></div>        
        <div style="align-self: center; z-index: 99"  >
            <div id="logo-container"><img src="../assets/beta.png" alt="" /></div>
        </div>
        <div style="height: 20px"></div>
        <div style="width: 95%; text-align: center; z-index: 99">
            <div style="max-width: 640px; margin: 0 auto; font-family: Helvetica, sans-serif">
                <div style="text-align: left; font-weight: bold; font-size: 16pt;">Welcome to SnooCare beta</div>
                <div style="text-align: left">Check out the video to get a sneek peek</div>
                <div style="height: 10px"></div>
                <div class="iframe-container">
                    <youtube style="border-radius: 15px;" :video-id="videoId" :resize="true" :fitParent="true" :player-vars="playerVars"  ref="youtube"></youtube>
                </div>
                <div style="height: 10px"></div>                
                <div style="text-align: left">If you own a Snooker table at home, please click on details below to learn about our beta program and fill out the form to apply.</div>
                <div style="text-align: left">We will select a few dozen people.</div>
            </div>
        </div>
        <div style="height: 20px"></div>
        <div>Registration to Beta is closed</div>
        <div style="height: 20px"></div>
        <div style="display: flex; flex-direction: row" v-if="false">
            <v-btn color="warning" dark class="text-normal" @click="moreDetails" rounded>Details & Pricing</v-btn>
            <div style="width: 20px"></div>
            <v-btn color="success" @click="clickToApply" dark rounded>Click to register</v-btn>
        </div>
        <div style="height: 20px"></div>
        <div style="display: flex; flex-direction: row">
            <a href="https://www.facebook.com/SnooCare" target="_blank"><v-img :src=" require('../assets/facebook-dark.png')" width="32" height="32" contain ></v-img></a>
            <div style="width: 10px"></div>
            <a href="https://www.youtube.com/channel/UC0URsp-xZ7-Cii93_U8FZqA" target="_blank"><v-img :src=" require('../assets/youtube-dark.png')" width="32" height="32" contain ></v-img></a>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            videoId: 'dk9jvUwJ-cw',
            playerVars: {
                controls: 1
            }        
        };
    },
    methods: {
        moreDetails() {
            window.open('https://snoocare.com/files/one_pager.pdf');
        },
        clickToApply() {
            window.open('https://forms.gle/NRwdKDQwto8qv2oN7');
        }
    },
}
</script>

<style> 
#logo-container img {
   width: 100%;
   height: auto;
}
.iframe-container{
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; 
  height: 0;
  background-color: black;
  border-radius: 15px;
}
.iframe-container iframe{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 3px 3px 8px #00000090;
}

.beta-page {
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: center;
}


.demo-bg {
    opacity: 0.4;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: url('../assets/images/bg-element.png');
    background-repeat: no-repeat;
    background-size: cover;      
    z-index: 0;
}

</style>